var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid home-page" },
    [
      _c("div", { staticClass: "row layout-horizontal_middle-page" }, [
        _c(
          "div",
          {
            staticClass:
              "col logo-panel d-flex justify-content-lg-center align-items-center full-height-lg py-1 py-lg-0"
          },
          [
            _c("router-link", { attrs: { to: { name: "home" } } }, [
              _c("h1", { staticClass: "m-0 pl-2" }, [
                _c("img", {
                  staticClass: "logo-panel_logo",
                  attrs: {
                    src: require("@/app/imgs/logo-zipvisual-white.png"),
                    alt: "Zipvisual logo",
                    title: "Zipvisual"
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "logo-panel_icons d-none d-lg-flex align-self-start"
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticStyle: {
                      "font-size": "54px",
                      "margin-top": "-10px",
                      "margin-left": "-2px"
                    },
                    attrs: {
                      target: "_blank",
                      href: "https://api.whatsapp.com/send?phone=5213327913995"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "z-icon z-icon--center text-white" },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 448 512"
                            }
                          },
                          [
                            _c("defs"),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("z-begin-project", {
              staticClass: "d-none d-lg-block logo-panel_cta",
              attrs: { white: "", "hide-icon": "" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_page full-height-lg align-items-stretch"
        },
        [
          _c(
            "div",
            {
              staticClass: "col main-banner",
              style: { backgroundImage: "url(" + _vm.mainBanner + ")" }
            },
            [
              _c("div", { staticClass: "row full-height" }, [
                _c(
                  "div",
                  { staticClass: "col align-self-end pl-3 py-2" },
                  [
                    _c("h2", { staticClass: "main-banner_title" }, [
                      _vm._v(_vm._s(_vm.$t("home.design")))
                    ]),
                    _vm._v(" "),
                    _c("h2", { staticClass: "main-banner_title" }, [
                      _vm._v(_vm._s(_vm.$t("home.development")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "main-banner_title main-banner_title--stroked"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("home.marketing")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("icon-scroll", {
                      staticClass: "main-banner_icon d-none d-lg-block"
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("h2", { staticClass: "main-banner_vertical-title" }, [
                    _vm.$i18n.locale === "es"
                      ? _c("img", {
                          attrs: {
                            src: require("@/app/imgs/banner-somos-zipvisual-es.png"),
                            alt: "texto somos zipvisual",
                            title: "Somos Zipvisual"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/app/imgs/banner-somos-zipvisual-en.png"),
                            alt: "text we are zipvisual",
                            title: "We Are Zipvisual"
                          }
                        })
                  ])
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("z-we-section", { attrs: { "show-more": "" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row layout-horizontal_page new-section full-height-lg"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-6 order-lg-2 d-flex justify-content-center align-items-center"
            },
            [
              _c(
                "div",
                {
                  staticClass: "row justify-content-center align-items-center"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col col-lg-8 p-3 p-lg-3",
                      attrs: {
                        "data-iv": "mamey",
                        "data-aos-anchor": ".layout-horizontal_wrapper"
                      }
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "new-section_subtitle text-center text-lg-left"
                        },
                        [_vm._v("\n            - PORTAFOLIO -\n          ")]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "new-section_text" }, [
                        _vm._v(
                          "\n            Desarrollamos proyectos integrales buscando la mejor solución para\n            nuestros clientes.\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "portfolio" } } },
                        [
                          _c(
                            "button",
                            { staticClass: "circle-button mt-3 mt-lg-5" },
                            [_vm._v("ver más")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-6 order-lg-1" },
            [
              _c(
                "swiper",
                {
                  ref: "carouselProjects",
                  staticClass: "new-section_carousel",
                  attrs: { options: _vm.optionsSwiperProjects }
                },
                [
                  _vm._l(Math.ceil(_vm.lastProjects.length / 2), function(
                    projectIndex
                  ) {
                    return [
                      _c("swiper-slide", [
                        _c("div", { staticClass: "container-fluid h-100" }, [
                          _c("div", { staticClass: "row h-100" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-lg-6 p-0" },
                              [
                                _c("z-project", {
                                  attrs: {
                                    project:
                                      _vm.lastProjects[(projectIndex - 1) * 2]
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.lastProjects[(projectIndex - 1) * 2 + 1]
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-lg-6 p-0" },
                                  [
                                    _c("z-project", {
                                      attrs: {
                                        project:
                                          _vm.lastProjects[
                                            (projectIndex - 1) * 2 + 1
                                          ]
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm._m(3)
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_page services-section full-height-lg align-items-center"
        },
        [
          _c("div", {
            staticClass:
              "col-lg-2 d-none d-lg-block services-section_banner align-self-stretch"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-lg-9 px-3 pl-lg-4" }, [
            _c("div", { staticClass: "row mt-3 mt-lg-0" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("h2", { staticClass: "services-section_title" }, [
                    _vm._v(_vm._s(_vm.$t("Servicios")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "h3",
                    { staticClass: "services-section_subtitle d-inline-block" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("DISEÑO INNOVACIÓN & "))
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("ESTRATEGIA")))]),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("PARA")))]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("VENDER MÁS")))])
                    ]
                  ),
                  _vm._v(" "),
                  _c("z-begin-project", { staticClass: "ml-lg-3" })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3 justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-8" },
                [
                  _c(
                    "swiper",
                    {
                      ref: "carouselServices",
                      attrs: { options: _vm.optionsSwiperServices }
                    },
                    [
                      _c("swiper-slide", [
                        _c(
                          "div",
                          { staticClass: "row services-section_service" },
                          [
                            _c("div", { staticClass: "col-12 col-md-8" }, [
                              _c(
                                "h3",
                                {
                                  staticClass: "services-section_service_title"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("Diseño Gráfico")) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "La imagen es fundamental para lograr el impacto en tus clientes"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [_vm._v("Branding")]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Imagen Corporativa")))
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Diseño Editorial")))
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Diseño Publicitario")))
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Fotografía")))
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(_vm._s(_vm.$t("Impresión")))])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-4 text-right text-lg-left"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/app/imgs/icon-mouse.png"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      { attrs: { to: { name: "design" } } },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "circle-button position-absolute"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.$t("ver más")) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("swiper-slide", [
                        _c(
                          "div",
                          { staticClass: "row services-section_service" },
                          [
                            _c("div", { staticClass: "col-12 col-md-8" }, [
                              _c(
                                "h3",
                                {
                                  staticClass: "services-section_service_title"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t("Paginas Web, Sistemas & Apps")
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "Los medios digitales fortalecen a tu empresa y la ponen al alcance de todo el mundo"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "Diseño y desarrollo de Sitios Web"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("Administrador de contenidos")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Sistemas a tu medida")))
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(_vm._s(_vm.$t("Ecommerce")))]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Apps iOS + Android")))
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Consultoría Web")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-4 text-right text-lg-left"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/app/imgs/icon-cursor.png"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: { name: "development" } }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "circle-button position-absolute"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.$t("ver más")) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("swiper-slide", [
                        _c(
                          "div",
                          { staticClass: "row services-section_service" },
                          [
                            _c("div", { staticClass: "col-12 col-md-8" }, [
                              _c(
                                "h3",
                                {
                                  staticClass: "services-section_service_title"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("Marketing Digital")) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "La mejor estrategia para llegar a tu público meta e incrementar las ventas que estas buscando"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("Administración de Redes Sociales")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("SEM campañas en Google Adwords")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("ADS campañas en Facebook"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("SEO posicionamiento web"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Publicidad en Línea")))
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(_vm._s(_vm.$t("Email Marketing")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-4 text-right text-lg-left"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/app/imgs/icon-lighter.png"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      { attrs: { to: { name: "marketing" } } },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "circle-button position-absolute"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.$t("ver más")) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "swiper-pagination",
                        attrs: {
                          slot: "pagination",
                          id: "carouselServicesPagination"
                        },
                        slot: "pagination"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-3 d-none d-lg-block" }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled services-section_points-list" },
                  [
                    _c("li", [_vm._v(_vm._s(_vm.$t("ANALISIS")))]),
                    _vm._v(" "),
                    _c("li", [_vm._v(_vm._s(_vm.$t("OBJETIVOS")))]),
                    _vm._v(" "),
                    _c("li", [_vm._v(_vm._s(_vm.$t("DESARROLLO")))]),
                    _vm._v(" "),
                    _c("li", [_vm._v(_vm._s(_vm.$t("PROYECTO")))]),
                    _vm._v(" "),
                    _c("li", [_vm._v(_vm._s(_vm.$t("SEGUIMIENTO")))]),
                    _vm._v(" "),
                    _c("li", [_vm._v(_vm._s(_vm.$t("ÉXITO")))])
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-6 p-3 pr-lg-5 align-items-center justify-content-center d-flex contact-section"
          },
          [_c("z-contact")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 p-3 p-lg-0 newsletter-section" }, [
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center align-items-center full-height-lg"
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("h2", { staticClass: "newsletter-section_title" }, [
                    _vm._v("Newsletter")
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "newsletter-section_subtitle" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("Recibe nuestros artículos y promociones")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("z-newsletter")
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row layout-horizontal_middle-page blog-section" },
        [
          _c(
            "div",
            {
              staticClass:
                "col p-3 p-lg-0 align-items-center justify-content-lg-center d-flex"
            },
            [
              _c(
                "div",
                [
                  _c("h2", { staticClass: "blog-section_title" }, [
                    _vm._v("BLOG")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.posts, function(post) {
                    return _c(
                      "a",
                      {
                        key: post.id,
                        staticClass: "blog-section_post mt-3",
                        attrs: { href: post.link, target: "_blank" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "blog-section_post_image" },
                          [
                            post._embedded &&
                            post._embedded["wp:featuredmedia"] &&
                            post._embedded["wp:featuredmedia"][0] &&
                            post._embedded["wp:featuredmedia"][0]["link"]
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "270px",
                                    height: "154px"
                                  },
                                  attrs: {
                                    src:
                                      post._embedded["wp:featuredmedia"][0][
                                        "link"
                                      ],
                                    fit: "cover"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pl-3" },
                          [
                            _c(
                              "span",
                              { staticClass: "blog-section_post_date" },
                              [_vm._v(_vm._s(_vm.formatDate(post.date_gmt)))]
                            ),
                            _vm._v(" "),
                            _c(
                              "h3",
                              { staticClass: "blog-section_post_title" },
                              [_vm._v(_vm._s(post.title.rendered))]
                            ),
                            _vm._v(" "),
                            _c("z-icon", {
                              staticClass: "blog-section_post_icon",
                              attrs: { icon: "chevron-right" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._m(4)
                ],
                2
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "tel:3327913995" } }, [
      _c("img", {
        attrs: { src: require("@/app/imgs/icon-circle-phone.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "mailto:contacto@zipvisual.com" } }, [
      _c("img", {
        staticClass: "mt-1",
        attrs: { src: require("@/app/imgs/icon-circle-envelope.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h2",
      { staticClass: "new-section_title text-center text-lg-left" },
      [_vm._v("\n            LO MÁS "), _c("br"), _c("span", [_vm._v("NUEVO")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-section_navigation" }, [
      _c("div", {
        staticClass: "swiper-button-prev swiper-button-black",
        attrs: { id: "carouselProjectsPrev" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-next swiper-button-black",
        attrs: { id: "carouselProjectsNext" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "https://www.zipvisual.com/blog/" } }, [
      _c("button", { staticClass: "circle-button mt-3" }, [_vm._v("ver más")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }