import $ from 'jquery'


setTimeout(function () {
    removePreloader();
}, 3500);

$(window).on('load', function () {
    removePreloader();
});

function removePreloader() {
    $("#preloader").slideUp(400);
    $("body").removeClass('position-fixed');
}