<template>
	<div class="z-gmap" ref="map"></div>
</template>

<script>
	import iconMark from '@/app/imgs/map-mark.png'

    export default {
        name: "ZGmap",
        methods: {
            initMap() {
                let geocoder = new google.maps.Geocoder();
                let latlng = new google.maps.LatLng(20.65335323165784, -103.30938518056485);
                let styles = [{
                    "featureType": "landscape",
                    "stylers": [{"hue": "#000000"}, {"saturation": 0}, {"lightness": 50}, {"gamma": 1}]
                }, {
                    "featureType": "road.highway",
                    "stylers": [{"hue": "#000000"}, {"saturation": 0}, {"lightness": 100}, {"gamma": 1}]
                }, {
                    "featureType": "road.arterial", /* lineas de las calles*/
                    "stylers": [{"hue": "#E1DDD5"}, {"saturation": 0}, {"lightness": 0}, {"gamma": 1}]
                }, {
                    "featureType": "road.local", /* lineas de las calles*/
                    "stylers": [{"hue": "#E1DDD5"}, {"saturation": 0}, {"lightness": 0}, {"gamma": 1}]
                }, {
                    "featureType": "water",
                    "stylers": [{"hue": "#C9E8E1"}, {"saturation": 63.19999999999999}, {"lightness": -13.799999999999983}, {"gamma": 1}]
                }, {
                    "featureType": "poi", /* puntos de inter�s*/
                    "stylers": [{"hue": "#83bc03"}, {"saturation": 0}, {"lightness": 0}, {"gamma": 1}]
                }];
                let mapOptions = {
                    zoom: 17,
                    center: latlng,
                    scrollwheel: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    panControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.TOP_LEFT
                    },
                    styles: styles
                };
                let map = new google.maps.Map(this.$refs.map, mapOptions);
                let point = new google.maps.LatLng(20.65335323165784, -103.30938518056485);
                let mark = new google.maps.Marker({map: map, icon: iconMark, position: point});
            }
        },
        mounted() {
            this.$nextTick(this.initMap);
        }
    }
</script>

<style scoped>

</style>
