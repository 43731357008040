var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "z-menu", class: { "z-menu--open": _vm.isMenuOpen } },
    [
      _c("div", { staticClass: "z-menu_center-bar d-none d-lg-block" }),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row mt-3 mt-lg-5" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-6 text-right pr-lg-3 order-2 order-lg-1"
            },
            [
              _c("ul", { staticClass: "list-unstyled" }, [
                _c(
                  "li",
                  { staticClass: "z-menu_item--bold" },
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _vm._v(_vm._s(_vm.$t("Inicio")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item--bold" },
                  [
                    _c("router-link", { attrs: { to: { name: "company" } } }, [
                      _vm._v("Zipvisual")
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "list-unstyled mt-3" }, [
                _c("li", { staticClass: "z-menu_item--bold" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Servicios")))])
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item" },
                  [
                    _c("router-link", { attrs: { to: { name: "design" } } }, [
                      _vm._v(_vm._s(_vm.$t("Diseño grafico")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "development" } } },
                      [_vm._v(_vm._s(_vm.$t("Sitios web, sistemas, apps")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "marketing" } } },
                      [_vm._v(_vm._s(_vm.$t("Marketing digital")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item" },
                  [
                    _c("router-link", { attrs: { to: { name: "hosting" } } }, [
                      _vm._v(_vm._s(_vm.$t("Dominios y hospedajes")))
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "list-unstyled mt-3" }, [
                _c(
                  "li",
                  { staticClass: "z-menu_item--bold" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "portfolio" } } },
                      [_vm._v(_vm._s(_vm.$t("Portafolio")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "z-menu_item--bold" }, [
                  _c(
                    "a",
                    { attrs: { href: "https://www.zipvisual.com/blog/" } },
                    [_vm._v(_vm._s(_vm.$t("Blog")))]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item--bold" },
                  [
                    _c("router-link", { attrs: { to: { name: "contact" } } }, [
                      _vm._v(_vm._s(_vm.$t("Contacto")))
                    ])
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-6 pl-2 align-self-center order-1 text-center text-lg-left mb-3 pl-lg-3"
            },
            [
              _c("router-link", { attrs: { to: { name: "home" } } }, [
                _c("img", {
                  staticClass: "z-menu_logo",
                  attrs: {
                    src: require("@/app/imgs/logo-zipvisual-white.png"),
                    alt: "Logo zipvisual",
                    title: "Zipvisual"
                  }
                })
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-6 text-center text-lg-right pr-lg-3 pt-2"
            },
            [
              _c("ul", { staticClass: "list-unstyled" }, [
                _c(
                  "li",
                  {
                    staticClass:
                      "z-menu_item d-inline-block d-lg-block mr-1 mr-lg-0"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.facebook.com/zipvisualdisenowebgraficoguadalajara",
                          target: "_blank"
                        }
                      },
                      [_c("z-icon", { attrs: { icon: "facebook" } })],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "z-menu_item d-inline-block d-lg-block mr-1 mr-lg-0 mt-1"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.instagram.com/zipvisual/",
                          target: "_blank"
                        }
                      },
                      [_c("z-icon", { attrs: { icon: "instagram" } })],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "z-menu_item d-inline-block d-lg-block mt-1" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://x.com/zipvisual_mx",
                          target: "_blank"
                        }
                      },
                      [_c("z-icon", { attrs: { icon: "twitter" } })],
                      1
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-6 text-center text-lg-left pl-lg-3" },
            [
              _c(
                "div",
                { staticClass: "z-menu_logo-panel_icons d-flex flex-column" },
                [
                  _c(
                    "a",
                    { attrs: { href: "tel:3327913995" } },
                    [
                      _c("z-icon", {
                        attrs: { icon: "circle-phone", center: "" }
                      }),
                      _vm._v("\n\t\t\t\t\t\t\t33 2791 3995\n\t\t\t\t\t\t")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "pt-2",
                      attrs: { href: "mailto:contacto@zipvisual.com" }
                    },
                    [
                      _c("z-icon", {
                        attrs: { icon: "circle-envelope", center: "" }
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t\tcontacto@zipvisual.com\n\t\t\t\t\t\t"
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }