import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/es'
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'

//Plugins
import './plugins/asset'
import './plugins/preloader'

import App from './view/App'
import router from './router'
import i18n from './i18n'
import store from './store'

import '../styles/app.scss'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueRouter);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});
Vue.use(ElementUI, {locale});

new Vue({
    el: '#vueApp',
    router,
    store,
    i18n,
    render: h => h(App)
});
