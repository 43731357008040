<template>
	<div class="view-horizontal not-found-page">
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					Not found
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "NotFoundPage",
        metaInfo() {
            return {
                title: this.$t("Zipvisual - 404"),
            }
        },
	}
</script>

<style scoped>

</style>
