<template>
	<el-dialog
			title="Solicitud de Cotización"
			:visible="isRequestServiceVisible"
			:before-close="updateCloseState"
	>
		<el-form ref="form" :model="form" class="mt-4" label-position="top" v-loading="loading">
			<el-form-item prop="name" :rules="rules.required">
				<el-input maxlength="100" v-model="form.name" placeholder="Nombre completo*"></el-input>
			</el-form-item>
			<el-form-item prop="email" :rules="[...rules.required,...rules.email]">
				<el-input maxlength="100" v-model="form.email" placeholder="Email*"></el-input>
			</el-form-item>
			<el-form-item prop="phone" :rules="rules.required">
				<el-input maxlength="15" v-model="form.phone" placeholder="Móvil / Teléfono  con lada*"></el-input>
			</el-form-item>
			<el-form-item prop="company">
				<el-input maxlength="100" v-model="form.company" placeholder="Empresa"></el-input>
			</el-form-item>
			<div class="pl-2" v-if="servicesRequest && servicesRequest.domains.length">
				<h6>Dominios a solicitar</h6>
				<ul>
					<li v-for="domain in servicesRequest.domains">{{domain.domain}}</li>
				</ul>
			</div>
			<div class="pl-2" v-if="servicesRequest && servicesRequest.hosting !== 'none'">
				<h6>Hospedaje</h6>
				<p>{{servicesRequest.hosting}}</p>
			</div>
			<hr>
			<el-form-item prop="comments">
				<el-input v-model="form.comments"
						  :autosize="{ minRows: 2, maxRows: 3}"
						  maxlength="500"
						  show-word-limit
						  type="textarea"
						  placeholder="Comentarios"></el-input>
			</el-form-item>
			<div class="text-right">
				<el-button @click="updateCloseState" type="text">Cancelar</el-button>
				<el-button type="default" @click="sendForm">Aceptar</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>
    export default {
        name: "ZRequestService",
        computed: {
            servicesRequest() {
                return this.$store.state.app.servicesRequest;
            },
            isRequestServiceVisible() {
                return this.$store.state.app.isRequestServiceVisible;
            },
            rules() {
                return {
                    email: [{type: "email", trigger: 'blur', message: 'Ingrese un email válido.'}],
                    required: [{required: true, trigger: 'blur', message: 'Este campo es requerido.'}]
                }
            }
        },
        data() {
            return {
                loading: false,
                form: {}
            }
        },
        methods: {
            updateCloseState() {
                this.$store.commit("app/CLOSE_REQUEST_SERVICE");
            },
            clearForm() {
                this.form = {};
                this.$store.commit("app/SET_SERVICES_REQUEST", null);
            },
            sendForm() {
                this.$refs.form.validate(isValid => {
                    if (isValid) {
                        this.loading = true;
                        let data = {
                            ...this.form,
                            ...{"services": this.servicesRequest}
                        };
                        $.post(LINKS.servicesForm, data)
                            .done(() => {
                                this.$notify({
                                    title: 'Éxito',
                                    message: 'Se ha enviado su solicitud, en breve nos pondremos en contacto muchas gracias.',
                                    type: 'success'
                                });

                                this.clearForm();
                                this.updateCloseState();
                            })
                            .fail((e) => {
                                console.error(e);
                                this.$notify({
                                    title: 'Error',
                                    message: 'Se ha producido un error al enviar el formulario, por favor intente más tarde.',
                                    type: 'error'
                                })
                            })
                            .always(() => {
                                this.loading = false;
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>