var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid company-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row banner-section" }, [
        _c("div", { staticClass: "col banner-section_banner my-3 my-lg-0" }, [
          _c(
            "div",
            { staticClass: "row flex-column full-height-lg d-none d-lg-flex" },
            [
              _c("div", { staticClass: "col d-flex pl-3 align-items-center" }, [
                _c(
                  "div",
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\tContenedor de ideas...\n\t\t\t\t\t\t"
                    ),
                    _c("z-icon", { attrs: { icon: "lightbulb" } })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col d-flex justify-content-end align-items-end"
                },
                [
                  _vm.$i18n.locale === "es"
                    ? _c("img", {
                        staticClass: "banner-section_weare",
                        attrs: {
                          src: require("@/app/imgs/banner-somos-es.png"),
                          title: "Somos"
                        }
                      })
                    : _c("img", {
                        staticClass: "banner-section_weare",
                        attrs: {
                          src: require("@/app/imgs/banner-somos-en.png"),
                          title: "We Are"
                        }
                      })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _vm._m(2)
      ]),
      _vm._v(" "),
      _c("z-we-section"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row my-4 my-lg-0 layout-horizontal_middle-page justify-content-center align-items-center brochure-section"
        },
        [
          _c("div", { staticClass: "col-auto" }, [
            _c("div", { staticClass: "brochure-section_message" }, [
              _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "brochure-section_message_min" }, [
                  _vm._v("Diseño es")
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v("\n\t\t\t\t\tVolver visible "),
                _c("br"),
                _vm._v("lo invisible "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$asset("docs/brochure-zipvisual.pdf"),
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "brochure-section_button" },
                      [
                        _vm._v("brochure\n\t\t\t\t\t\t\t"),
                        _c("z-icon", { attrs: { icon: "download" } })
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-5 p-3 objectives-section d-flex justify-content-center align-items-center"
          },
          [
            _c("div", [
              _c("h2", { staticClass: "objectives-section_title" }, [
                _vm._v("Objetivos")
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "i-list objectives-section_list" }, [
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Entera satisfación del cliente\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Reconocimiento de nuestros clientes\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Atención personalizada\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Innovación en diseño\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Calidad en todos los servicios\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Trabajo en equipo\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Permanencia en el mercado\n\t\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "i-list_icon" },
                    [_c("z-icon", { attrs: { icon: "check" } })],
                    1
                  ),
                  _vm._v(" Comunicación auténtica\n\t\t\t\t\t")
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col my-4 my-lg-0 p-3 p-lg-4 mv-section d-flex justify-content-center align-items-center"
          },
          [
            _c("div", [
              _c("h2", { staticClass: "mv-section_title" }, [_vm._v("VISIÓN")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Ser una empresa líder en el mercado con una sólida estructura organizacional que proporcione bienestar a sus empleados, clientes y proveedores, sosteniendo un crecimiento y mejora integral, proyectando confianza en nuestro trabajo."
                )
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "mv-section_title mt-3" }, [
                _vm._v("MISIÓN")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Diseñar y comercializar servicios que mejoren la calidad de comunicación visual y estrategias de venta de nuestros clientes mediante atención personalizada, profesional y responsable, satisfaciendo las expectativas del mercado y alcanzando los niveles de rentabilidad deseados."
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center text-lg-left" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "contact" } } },
                    [_c("z-begin-project", { staticClass: "mt-5" })],
                    1
                  )
                ],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row layout-horizontal_middle-page title-section mt-3 mt-lg-0"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "col d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0"
          },
          [
            _c("div", [
              _c("h2", { staticClass: "title-section_title" }, [
                _c("span", [_vm._v("DISEÑAMOS")]),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v("LA IMAGEN")]),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v("de tu...")]),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v("EMPRESA")]),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v("&")]),
                _vm._v(" "),
                _c("span", [_vm._v("PRODUCTO")])
              ])
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-lg-none" }, [
      _c("div", { staticClass: "col px-3 py-5" }, [
        _c("h1", { staticClass: "banner-section_title" }, [
          _vm._v("SOMOS\n\t\t\t\t\t\t"),
          _c("span", { staticClass: "banner-section_title_stroked" }, [
            _vm._v("ZIPVISUAL")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto d-none d-lg-block" }, [
      _c("img", {
        staticClass: "banner-section_zipvisual",
        attrs: {
          src: require("@/app/imgs/banner-zipvisual.png"),
          title: "Zipvisual",
          alt: "Zipvisual"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }