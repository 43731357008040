var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-horizontal_wrapper container-fluid development-page"
    },
    [
      _c("z-header"),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page title-section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-6 order-lg-1 d-flex justify-content-center align-items-center full-height-lg py-4 py-lg-0"
          },
          [
            _c("div", { staticClass: "pl-0 pl-lg-5" }, [
              _c("h1", { staticClass: "title-section_title" }, [
                _c("span", { staticClass: "title-section_title_stroked" }, [
                  _vm._v(_vm._s(_vm.$t("PAGINAS")))
                ]),
                _vm._v(" " + _vm._s(_vm.$t("WEB")))
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "title-section_subtitle" }, [
                _vm._v(_vm._s(_vm.$t("Sistemas & Apps")))
              ]),
              _vm._v(" "),
              _vm._m(1)
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page apps-section align-items-center full-height-lg py-4 py-lg-0"
        },
        [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-12 d-flex flex-row justify-content-center align-items-end justify-content-lg-end"
                },
                [
                  _c(
                    "div",
                    { staticClass: "apps-section_icons mb-5 mr-3" },
                    [
                      _c("z-icon", { attrs: { icon: "apple" } }),
                      _vm._v(" "),
                      _c("z-icon", { attrs: { icon: "android" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("z-phone", { staticClass: "mt-lg-n4 d-none d-lg-block" })
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0"
        },
        [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-12 justify-content-center justify-content-lg-end order-lg-1 d-none d-lg-flex"
                },
                [_c("z-fingerprint", { staticClass: "my-n4" })],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0"
        },
        [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-12 py-3 justify-content-center d-none d-lg-flex"
                },
                [_c("z-shoppingcar")],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0"
        },
        [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 order-1 order-lg-2 px-lg-4" }, [
                _c("div", { staticClass: "info-service" }, [
                  _c("span", { staticClass: "info-service_number" }, [
                    _vm._v("04")
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "info-service_title" }, [
                    _vm._v("Dominios y Hospedaje")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info-service_description" },
                    [
                      _c("p", [
                        _vm._v(
                          "Te ofrecemos el servicio de registro de dominios y los mejores planes de hospedaje para tu página web. Además te brindamos el servicio de soporte técnico para tu página web."
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "hosting" } } },
                        [
                          _c(
                            "button",
                            { staticClass: "info-service_btn mt-3" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tMás Información\n\t\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-12 py-3 justify-content-center order-2 order-lg-1 d-none d-lg-flex"
                },
                [_c("z-cloudcomputing")],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row layout-horizontal_600-page brochure-section" },
        [
          _c(
            "div",
            {
              staticClass:
                "col-12 d-flex flex-column justify-content-around align-items-center full-height-lg py-3 py-lg-0"
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/app/imgs/icon-responsive-design.png"),
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$asset("docs/brochure-zipvisual.pdf"),
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "circle-button circle-button--big brochure-button mt-3"
                    },
                    [
                      _c("span", [_vm._v("brochure")]),
                      _vm._v(" "),
                      _c("z-icon", { attrs: { icon: "download" } })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-12 col-lg-6 order-lg-2 d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0 computer-section"
      },
      [
        _c("img", {
          staticClass: "computer-section_mouse",
          attrs: { src: require("@/app/imgs/mouse.png"), alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Ofrecemos el servicio de diseño y desarrollo de paginas web, sistemas y administradores web a la medida, también diseñamos y desarrollamos Apps para Android e iOS o incluso CMS como Wordpress como parte de nuestras soluciones digitales."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "Tenemos una solución a la medida de cada cliente, con un servicio integral que nos permite cubrir las necesidades con un diseño y desarrollo único, funcional y adecuado para cada proyecto.\n\t\t\t\t\t"
      ),
      _c("br"),
      _c("br"),
      _vm._v("Contamos con asesoría para tu proyecto.\n\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 px-lg-4" }, [
      _c("div", { staticClass: "info-service info-service--white" }, [
        _c("span", { staticClass: "info-service_number" }, [_vm._v("01")]),
        _vm._v(" "),
        _c("h2", { staticClass: "info-service_title" }, [
          _vm._v("Paginas Web y Apps")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-service_description" }, [
          _c("p", [
            _vm._v(
              "Diseñamos y desarrollamos sitios web, únicos, funcionales y responsivos para todos los dispositivos móviles y a la medida de cada cliente logrando una mejor experiencia de usuario.\n\t\t\t\t\t\t\t\t"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Creamos tu App en Android o iOS de acuerdo a las necesidades de cada proyecto.\n\t\t\t\t\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Diseño Responsivo de Paginas Web")]),
            _vm._v(" "),
            _c("li", [_vm._v("Rediseño de Sitios Web")]),
            _vm._v(" "),
            _c("li", [_vm._v("Diseño y desarrollo de Apss para Android + iOS")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 order-lg-2 px-lg-4" }, [
      _c("div", { staticClass: "info-service" }, [
        _c("span", { staticClass: "info-service_number" }, [_vm._v("02")]),
        _vm._v(" "),
        _c("h2", { staticClass: "info-service_title" }, [
          _vm._v("Desarrollo de Sistemas")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-service_description" }, [
          _c("p", [
            _vm._v(
              "Desarrollamos cualquier sistema de acuerdo a las necesidades de cada proyecto según los requerimientos de nuestros clientes obteniendo el mayor beneficio en cuanto a funcionalidad y estética."
            )
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Sistemas a la medida")]),
            _vm._v(" "),
            _c("li", [_vm._v("Administradores de contenido web")]),
            _vm._v(" "),
            _c("li", [_vm._v("Sistemas Administrativos o empresariales")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 px-lg-4" }, [
      _c("div", { staticClass: "info-service" }, [
        _c("span", { staticClass: "info-service_number" }, [_vm._v("03")]),
        _vm._v(" "),
        _c("h2", { staticClass: "info-service_title" }, [_vm._v("E-Commerce")]),
        _vm._v(" "),
        _c("div", { staticClass: "info-service_description" }, [
          _c("p", [
            _vm._v(
              "Internet es el presente y futuro de las ventas, Zipvisual te ofrece el diseño y desarrollo de tu propia tienda en línea ( e-commerce ) para vender tus productos con un administrador web que te permite poner promociones, actualizar tus productos y dar seguimiento a los pedidos de una forma sencilla."
            )
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Catálogo de Productos")]),
            _vm._v(" "),
            _c("li", [_vm._v("Tienda Online")]),
            _vm._v(" "),
            _c("li", [_vm._v("Metódos de Pago")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "mt-3" }, [
      _c("li", [_vm._v("Registro de Dominios")]),
      _vm._v(" "),
      _c("li", [_vm._v("Hospedaje Web")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }