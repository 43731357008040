<template>
	<div class="icon-scroll">
		<div class="mouse">
			<div class="wheel"></div>
		</div>
		<div class="icon-arrows">
			<span></span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "IconScroll"
	}
</script>

