import Router from 'vue-router'
import i18n from './i18n'
import store from './store'

const routes = [
    {
        path: "/",
        beforeEnter(to, from, next) {
            // let lang = (navigator.language || navigator.userLanguage).split("-")[0];
            //TODO lang debe ser es o en
            let lang = "es";
            next(lang)
        }
    },
    {
        path: "/:lang",
        component: require("./layout/FrontLayout").default,
        children: [
            {name: "home", path: "", component: require('./pages/HomePage').default},
            {name: "contact", path: i18n.t('routes.contact'), component: require('./pages/ContactPage').default},
            {name: "company", path: i18n.t('routes.company'), component: require('./pages/CompanyPage').default},
            {name: "design", path: i18n.t('routes.design'), component: require('./pages/DesignPage').default},
            {name: "hosting", path: i18n.t('routes.hosting'), component: require('./pages/HostingPage').default},
            {name: "portfolio", path: i18n.t('routes.portfolio'), component: require('./pages/PortfolioPage').default},
            {name: "services", path: i18n.t('routes.services'), component: require('./pages/ServicesPage').default},
            {name: "marketing", path: i18n.t('routes.marketing'), component: require('./pages/MarketingPage').default},
            {
                name: "development",
                path: i18n.t('routes.development'),
                component: require('./pages/DevelopmentPage').default
            },
            {name: "notFound", path: "*", component: require('./pages/NotFoundPage').default},
        ]
    }
];

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    setTimeout(resetScroll, 250);
    store.commit('app/CLOSE_MENU');
    store.commit('app/CLOSE_PROJECT_VIEWER');
    next();
});

function resetScroll() {
    const container = document.querySelector(".layout-horizontal_container");
    container && container.scroll(0, 0);
    window.scroll(0, 0); //On responsive
}

export default router;
