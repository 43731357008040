var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid services-page" },
    [
      _c("div", { staticClass: "d-block d-md-none" }, [_c("z-header")], 1),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page title-section justify-content-center align-items-center full-height-lg py-1 py-lg-0"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "col title-section d-flex justify-content-lg-center align-items-center full-height-lg py-1 py-lg-0"
            },
            [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("@/app/imgs/logo-zipvisual-green.png"),
                    alt: "Zipvisual logo",
                    title: "Zipvisual"
                  }
                }),
                _vm._v(" "),
                _c("h1", { staticClass: "title-section_title" }, [
                  _c("span", [_vm._v("NUESTROS")]),
                  _c("br"),
                  _vm._v("SERVICIOS\n\t\t\t\t\t"),
                  _c(
                    "button",
                    {
                      staticClass: "d-inline-flex circle-button",
                      on: { click: _vm.goToServices }
                    },
                    [_c("z-icon", { attrs: { icon: "chevron-right" } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm._m(0)
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "title-section_icons d-none d-lg-flex align-self-start flex-column"
      },
      [
        _c("a", { attrs: { href: "tel:3327913995" } }, [
          _c("img", {
            attrs: { src: require("@/app/imgs/icon-circle-phone.png"), alt: "" }
          })
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "mailto:contacto@zipvisual.com" } }, [
          _c("img", {
            staticClass: "mt-1",
            attrs: {
              src: require("@/app/imgs/icon-circle-envelope.png"),
              alt: ""
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row page-1500 services-section justify-content-center align-items-center full-height-lg py-1 py-lg-0"
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col services-section_service",
                staticStyle: { background: "red" }
              },
              [
                _c(
                  "h2",
                  { staticClass: "services-section_service_title pl-3" },
                  [
                    _vm._v("PAGINAS WEB "),
                    _c("br"),
                    _c("span", [_vm._v("Sistemas & Apps")])
                  ]
                )
              ]
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }