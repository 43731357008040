<template>
	<div class="z-shoppingcar">
		<img class="z-shoppingcar_car" src="@/app/imgs/car.png" alt="">
		<img class="z-shoppingcar_lines" src="@/app/imgs/lines.png" alt="">
		<img class="z-shoppingcar_w1" src="@/app/imgs/wheel.png" alt="">
		<img class="z-shoppingcar_w2" src="@/app/imgs/wheel.png" alt="">
		<img class="z-shoppingcar_pointer" src="@/app/imgs/pointer.png" alt="">
	</div>
</template>

<script>
    export default {
        name: "ZShoppingcar"
    }
</script>

<style scoped>

</style>
