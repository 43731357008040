<template>
	<div class="z-project bg-image" @click="showInProjectViewer" :style="{'background-image':`url('${$asset(project.gallery[0].content.path)}'`}">
		<div class="z-project_mask">
			<h3 class="m-0">{{project.clientName}}</h3>
			<ul class="z-project_mask_list">
				<li v-for="service in project.services">{{service}}</li>
			</ul>
			<button class="z-project_mask_button">
				<z-icon icon="chevron-right"></z-icon>
			</button>
		</div>
	</div>
</template>

<script>
    import ZIcon from "./ZIcon";

    export default {
        name: "ZProject",
        components: {ZIcon},
        props: {
            project: {
                required: true
            }
        },
        methods: {
            showInProjectViewer() {
                this.$store.dispatch('app/showProjectInViewer', this.project);
            }
        }
    }
</script>

<style scoped>

</style>
