const state = {
    banners: STATE.banners,
};

export const actions = {
    async loadBanners({commit}) {
        let banners = (await this.$axios.$get(`${ASSET_PATH}api/banners`)).data;
        commit('SET_BANNERS', {});
    },
};

const mutations = {
    SET_BANNERS(state, payload) {
        state.banners = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}