const state = {
    isMenuOpen: false,
    isRequestServiceVisible: false,
    servicesRequest: null,
    isPrivacyAdviceVisible: false,
    isProjectViewerVisible: true,
    projectInViewer: null
};

const mutations = {
    TOGGLE_MENU(state) {
        state.isMenuOpen = !state.isMenuOpen;
    },
    CLOSE_MENU(state) {
        state.isMenuOpen = false;
    },
    TOGGLE_REQUEST_SERVICE(state) {
        state.isRequestServiceVisible = !state.isRequestServiceVisible;
    },
    SET_SERVICES_REQUEST(state, servicesRequest) {
        state.servicesRequest = servicesRequest;
    },
    CLOSE_REQUEST_SERVICE(state) {
        state.isRequestServiceVisible = false
    },
    TOGGLE_PRIVACY_ADVICE(state) {
        state.isPrivacyAdviceVisible = !state.isPrivacyAdviceVisible;
    },
    CLOSE_PRIVACY_ADVICE(state) {
        state.isPrivacyAdviceVisible = false;
    },
    TOGGLE_PROJECT_VIEWER(state) {
        state.isProjectViewerVisible = !state.isProjectViewerVisible;
    },
    CLOSE_PROJECT_VIEWER(state) {
        state.isProjectViewerVisible = false
    },
    SET_PROJECT_IN_VIEWER(state, project) {
        state.projectInViewer = project;
    }
};

const actions = {
    showProjectInViewer({commit}, project) {
        commit('SET_PROJECT_IN_VIEWER', project);
        commit('TOGGLE_PROJECT_VIEWER');
    },
    closeProjectViewer({commit}) {
        commit('SET_PROJECT_IN_VIEWER', null);
        commit('CLOSE_PROJECT_VIEWER');
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
