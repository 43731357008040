<template>
	<div class="layout-horizontal_wrapper container-fluid company-page">
		<z-header/>
		<div class="row layout-horizontal_middle-page title-section mt-3 mt-lg-0">
			<div class="col d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0">
				<div>
					<h2 class="title-section_title">
						<span>DISEÑAMOS</span><br>
						<span>LA IMAGEN</span><br>
						<span>de tu...</span><br>
						<span>EMPRESA</span><br>
						<span>&</span> <span>PRODUCTO</span>
					</h2>
				</div>
			</div>
		</div>
		<div class="row banner-section">
			<div class="col banner-section_banner my-3 my-lg-0">
				<div class="row flex-column full-height-lg d-none d-lg-flex">
					<div class="col d-flex pl-3 align-items-center">
						<div>
							Contenedor de ideas...
							<z-icon icon="lightbulb"/>
						</div>
					</div>
					<div class="col d-flex justify-content-end align-items-end">
						<img class="banner-section_weare"
							 v-if="$i18n.locale === 'es'"
							 src="@/app/imgs/banner-somos-es.png"
							 title="Somos">
						<img class="banner-section_weare" v-else src="@/app/imgs/banner-somos-en.png" title="We Are">
					</div>
				</div>
				<div class="row d-lg-none">
					<div class="col px-3 py-5">
						<h1 class="banner-section_title">SOMOS
							<span class="banner-section_title_stroked">ZIPVISUAL</span></h1>
					</div>
				</div>
			</div>
			<div class="col-auto d-none d-lg-block">
				<img class="banner-section_zipvisual"
					 src="@/app/imgs/banner-zipvisual.png"
					 title="Zipvisual"
					 alt="Zipvisual">
			</div>
		</div>
		<z-we-section/>
		<div class="row my-4 my-lg-0 layout-horizontal_middle-page justify-content-center align-items-center brochure-section">
			<div class="col-auto">
				<div class="brochure-section_message">
					<div class="text-center">
						<span class="brochure-section_message_min">Diseño es</span> <br>
						Volver visible <br>lo invisible <br>
						<a :href="$asset('docs/brochure-zipvisual.pdf')" target="_blank">
							<button class="brochure-section_button">brochure
								<z-icon icon="download"/>
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_page">
			<div class="col-12 col-lg-5 p-3 objectives-section d-flex justify-content-center align-items-center">
				<div>
					<h2 class="objectives-section_title">Objetivos</h2>
					<ul class="i-list objectives-section_list">
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Entera satisfación del cliente
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Reconocimiento de nuestros clientes
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Atención personalizada
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Innovación en diseño
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Calidad en todos los servicios
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Trabajo en equipo
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Permanencia en el mercado
						</li>
						<li><span class="i-list_icon">
							<z-icon icon="check"/>
						</span> Comunicación auténtica
						</li>
					</ul>
				</div>
			</div>
			<div class="col my-4 my-lg-0 p-3 p-lg-4 mv-section d-flex justify-content-center align-items-center">
				<div>
					<h2 class="mv-section_title">VISIÓN</h2>
					<p>Ser una empresa líder en el mercado con una sólida estructura organizacional que proporcione bienestar a sus empleados, clientes y proveedores, sosteniendo un crecimiento y mejora integral, proyectando confianza en nuestro trabajo.</p>
					<h2 class="mv-section_title mt-3">MISIÓN</h2>
					<p>Diseñar y comercializar servicios que mejoren la calidad de comunicación visual y estrategias de venta de nuestros clientes mediante atención personalizada, profesional y responsable, satisfaciendo las expectativas del mercado y alcanzando los niveles de rentabilidad deseados.</p>
					<div class="text-center text-lg-left">
						<router-link :to="{name:'contact'}">
							<z-begin-project class="mt-5"/>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZFooter from "@/app/js/components/ZFooter";
    import ZWeSection from "@/app/js/components/ZWeSection";
    import ZHeader from "@/app/js/components/ZHeader";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZBeginProject from "@/app/js/components/ZBeginProject";

    export default {
        components: {ZBeginProject, ZIcon, ZHeader, ZWeSection, ZFooter},
        metaInfo() {
            return {
				title: this.$t("Somos una Agencia de Diseño web y diseño gráfico en Guadalajara, Jalisco."),
				meta: [{
					vmid: 'description',
					name: 'description',
					content: this.$t("En nuestra agencia de Diseño y desarrollo web, creamos proyecto de alta calidad de diseño grafico y utilizamos las mejores tecnologías para nuestros proyectos de desarrollo web")
				}],
            }
        },
    }
</script>

<style scoped>

</style>
