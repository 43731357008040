var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "z-project bg-image",
      style: {
        "background-image":
          "url('" + _vm.$asset(_vm.project.gallery[0].content.path) + "'"
      },
      on: { click: _vm.showInProjectViewer }
    },
    [
      _c("div", { staticClass: "z-project_mask" }, [
        _c("h3", { staticClass: "m-0" }, [
          _vm._v(_vm._s(_vm.project.clientName))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "z-project_mask_list" },
          _vm._l(_vm.project.services, function(service) {
            return _c("li", [_vm._v(_vm._s(service))])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "z-project_mask_button" },
          [_c("z-icon", { attrs: { icon: "chevron-right" } })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }