var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "z-project-viewer",
      class: { "z-project-viewer--open": _vm.isOpen },
      on: { click: function($event) {} }
    },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "z-project-viewer_close",
            on: { click: _vm.closeViewer }
          },
          [_c("i", { staticClass: "el-icon-close" })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _vm.project
          ? _c("div", { staticClass: "row z-project-viewer_body" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-8" },
                [
                  _c(
                    "swiper",
                    { ref: "caroucel", attrs: { options: _vm.swiperOptions } },
                    _vm._l(_vm.project.gallery, function(image) {
                      return _c("swiper-slide", { key: image.content.__id }, [
                        _c("img", {
                          staticClass: "w-100",
                          attrs: {
                            src: _vm.$asset(image.content.path),
                            alt: ""
                          }
                        })
                      ])
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0)
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 pt-3 pl-lg-4" },
                [
                  _c("h3", { staticClass: "z-project-viewer_title" }, [
                    _vm._v("Cliente")
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.project.clientName))]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "z-project-viewer_title" }, [
                    _vm._v("Servicio")
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "z-project-viewer_list" },
                    _vm._l(_vm.project.services, function(service) {
                      return _c("li", [_vm._v(_vm._s(service))])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.project.webPage
                    ? [
                        _c("h3", { staticClass: "z-project-viewer_title" }, [
                          _vm._v("Visitar")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.project.webPage
                              }
                            },
                            [_vm._v(_vm._s(_vm.project.webPage))]
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "z-project-viewer_navs" }, [
      _c("div", {
        staticClass: "swiper-button-prev swiper-button-white",
        attrs: { id: "carouselProjectViewerPrev" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-next swiper-button-white",
        attrs: { id: "carouselProjectViewerNext" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }