import Vue from 'vue'
import Vuex from 'vuex'

import app from "@/app/js/store/app";
import banners from "@/app/js/store/banners";
import projects from "@/app/js/store/projects";
import clients from "@/app/js/store/clients";
import domainExtensions from "@/app/js/store/domainExtensions";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        banners,
        projects,
        domainExtensions,
        clients
    }
});
