<template>
	<div class="z-project-viewer" :class="{'z-project-viewer--open':isOpen}" @click="">
		<div>
			<div class="z-project-viewer_close" @click="closeViewer">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row z-project-viewer_body" v-if="project">
				<div class="col-12 col-lg-8">
					<swiper ref="caroucel" :options="swiperOptions">
						<swiper-slide v-for="image in project.gallery" :key="image.content.__id">
							<img class="w-100" :src="$asset(image.content.path)" alt="">
						</swiper-slide>
					</swiper>
					<div class="z-project-viewer_navs">
						<div id="carouselProjectViewerPrev" class="swiper-button-prev swiper-button-white"></div>
						<div id="carouselProjectViewerNext" class="swiper-button-next swiper-button-white"></div>
					</div>
				</div>
				<div class="col-12 col-lg-4 pt-3 pl-lg-4">
					<h3 class="z-project-viewer_title">Cliente</h3>
					<div>{{project.clientName}}</div>
					<h3 class="z-project-viewer_title">Servicio</h3>
					<ul class="z-project-viewer_list">
						<li v-for="service in project.services">{{service}}</li>
					</ul>
					<template v-if="project.webPage">
						<h3 class="z-project-viewer_title">Visitar</h3>
						<div><a target="_blank" :href="project.webPage">{{project.webPage}}</a></div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "ZProjectViewer",
        computed: {
            isOpen() {
                return this.$store.state.app.isProjectViewerVisible;
            },
            project() {
                return this.$store.state.app.projectInViewer;
            },
            swiperOptions() {
                return {
                    navigation: {
                        nextEl: "#carouselProjectViewerNext",
                        prevEl: "#carouselProjectViewerPrev",
                    },
                }
            }
        },
        methods: {
            closeViewer() {
                this.$store.dispatch('app/closeProjectViewer');
            }
        },
		watch:{
            isOpen(){
                console.log('rezise');
                $(window).trigger('resize');
			}
		}
    }
</script>

<style scoped>

</style>
