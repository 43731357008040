var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid contact-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page p-3 p-lg-0" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-6 align-items-center justify-content-center d-flex"
          },
          [_c("z-contact")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 newsletter-section mt-5 mt-lg-0" }, [
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center align-items-center full-height-lg"
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("h2", { staticClass: "newsletter-section_title" }, [
                    _vm._v("Newsletter")
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "newsletter-section_subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Recibe nuestros artículos y promociones"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("z-newsletter")
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_page begin-section full-height-lg align-items-center"
        },
        [
          _c(
            "div",
            {
              staticClass: "col-12 col-lg-3 p-3 mt-3 text-center text-lg-left"
            },
            [
              _c("z-icon", {
                staticClass: "begin-section_icon",
                attrs: { icon: "filled-circle-phone" }
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "begin-section_title mt-3 mb-0" }, [
                _vm._v("Comenzamos")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "begin-section_phone d-block",
                  attrs: { href: "tel:3327913995" }
                },
                [_vm._v("3327913995")]
              ),
              _vm._v(" "),
              _c("z-icon", {
                staticClass: "begin-section_icon mt-3",
                attrs: { icon: "filled-circle-envelope" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "begin-section_email d-block mt-3",
                  attrs: { href: "mailto:contacto@zipvisual.com" }
                },
                [_vm._v("contacto@zipvisual.com")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col full-height-lg map-section" },
            [_c("z-gmap", { staticClass: "map-section_map" })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }