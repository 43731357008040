<template>
	<div class="layout-horizontal_wrapper container-fluid services-page">
		<div class="d-block d-md-none">
			<z-header/>
		</div>
		<div class="row layout-horizontal_middle-page title-section justify-content-center align-items-center full-height-lg py-1 py-lg-0">
			<div class="col title-section d-flex justify-content-lg-center align-items-center full-height-lg py-1 py-lg-0">

				<div>
					<img src="@/app/imgs/logo-zipvisual-green.png" alt="Zipvisual logo"
						 title="Zipvisual">
					<h1 class="title-section_title">
						<span>NUESTROS</span><br>SERVICIOS
						<button @click="goToServices" class="d-inline-flex circle-button"><z-icon icon="chevron-right"/></button>
					</h1>

					<div class="title-section_icons d-none d-lg-flex align-self-start flex-column">
						<a href="tel:3327913995"><img src="@/app/imgs/icon-circle-phone.png" alt=""></a>
						<a href="mailto:contacto@zipvisual.com"><img class="mt-1"
																	 src="@/app/imgs/icon-circle-envelope.png"
																	 alt=""></a>
					</div>
				</div>

			</div>
		</div>
		<div class="row page-1500 services-section justify-content-center align-items-center full-height-lg py-1 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col services-section_service" style="background: red;">
						<h2 class="services-section_service_title pl-3">PAGINAS WEB <br><span>Sistemas & Apps</span></h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import ZIcon from "@/app/js/components/ZIcon";

    export default {
        name: "ServicesPage",
        components: {ZIcon},
        metaInfo() {
            return {
                title: this.$t("Conoce nuestros proyectos de creación de páginas web, diseño de logos y más"),
                meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: this.$t("Te presentamos nuestros trabajos realizados de Diseño gráfico y Desarrollo web para diferentes empresas, conoce lo que podemos hacer por tu negocio")
                }],
            }
        },
        methods: {
            goToServices() {
                let currentScroll = $('.layout-horizontal_container').scrollTop();
                let sectionOffset = $(".services-section").offset().left + $(".services-section").offset().top - 80;
                $('html, body, .layout-horizontal_container').animate({scrollTop: currentScroll + sectionOffset}, 1000);
            },
        }
    }
</script>

<style scoped>

</style>