<template>
	<div class="z-menu" :class="{'z-menu--open':isMenuOpen}">
		<div class="z-menu_center-bar d-none d-lg-block"></div>
		<div class="container-fluid">
			<div class="row mt-3 mt-lg-5">
				<div class="col-12 col-lg-6 text-right pr-lg-3 order-2 order-lg-1">
<!--					<router-link :to="{name:$route.name, params: {lang: $i18n.locale === 'es' ? 'en':'es' } }">-->
<!--						<div class="z-menu_lang">{{$i18n.locale === 'es' ? 'EN':'ES'}}</div>-->
<!--					</router-link>-->
					<ul class="list-unstyled">
						<li class="z-menu_item--bold">
							<router-link :to="{name:'home'}">{{$t('Inicio')}}</router-link>
						</li>
						<li class="z-menu_item--bold">
							<router-link :to="{name:'company'}">Zipvisual</router-link>
						</li>
					</ul>
					<ul class="list-unstyled mt-3">
						<li class="z-menu_item--bold">
							<span>{{$t('Servicios')}}</span>
						</li>
						<li class="z-menu_item">
							<router-link :to="{name:'design'}">{{$t('Diseño grafico')}}</router-link>
						</li>
						<li class="z-menu_item">
							<router-link :to="{name:'development'}">{{$t('Sitios web, sistemas, apps')}}</router-link>
						</li>
						<li class="z-menu_item">
							<router-link :to="{name:'marketing'}">{{$t('Marketing digital')}}</router-link>
						</li>
						<li class="z-menu_item">
							<router-link :to="{name:'hosting'}">{{$t('Dominios y hospedajes')}}</router-link>
						</li>
					</ul>
					<ul class="list-unstyled mt-3">
						<li class="z-menu_item--bold">
							<router-link :to="{name:'portfolio'}">{{$t('Portafolio')}}</router-link>
						</li>
						<li class="z-menu_item--bold">
							<a href="https://www.zipvisual.com/blog/">{{$t('Blog')}}</a>
						</li>
						<li class="z-menu_item--bold">
							<router-link :to="{name:'contact'}">{{$t('Contacto')}}</router-link>
						</li>
					</ul>
				</div>
				<div class="col-12 col-lg-6 pl-2 align-self-center order-1 text-center text-lg-left mb-3 pl-lg-3">
					<router-link :to="{name:'home'}">
						<img class="z-menu_logo" src="@/app/imgs/logo-zipvisual-white.png" alt="Logo zipvisual"
							 title="Zipvisual">
					</router-link>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12 col-lg-6 text-center text-lg-right pr-lg-3 pt-2">
					<ul class="list-unstyled">
						<li class="z-menu_item d-inline-block d-lg-block mr-1 mr-lg-0">
							<a href="https://www.facebook.com/zipvisualdisenowebgraficoguadalajara" target="_blank">
								<z-icon icon="facebook"/>
							</a>
						</li>
						<li class="z-menu_item d-inline-block d-lg-block mr-1 mr-lg-0 mt-1">
							<a href="https://www.instagram.com/zipvisual/" target="_blank">
								<z-icon icon="instagram"/>
							</a>
						</li>
						<li class="z-menu_item d-inline-block d-lg-block mt-1">
							<a href="https://x.com/zipvisual_mx" target="_blank">
								<z-icon icon="twitter"/>
							</a>
						</li>
					</ul>
				</div>
				<div class="col-12 col-lg-6 text-center text-lg-left pl-lg-3">
					<div class="z-menu_logo-panel_icons d-flex flex-column">
						<a href="tel:3327913995">
							<z-icon icon="circle-phone" center/>
							33 2791 3995
						</a>
						<a href="mailto:contacto@zipvisual.com" class="pt-2">
							<z-icon icon="circle-envelope" center/>
							contacto@zipvisual.com
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import ZIcon from "./ZIcon";

    export default {
        name: "ZMenu",
        components: {ZIcon},
        computed: {
            isMenuOpen() {
                return this.$store.state.app.isMenuOpen
            }
        },
    }
</script>
