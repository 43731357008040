var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid portfolio-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page title-section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8 px-lg-0" },
          [
            _c(
              "swiper",
              {
                ref: "carouselProjects",
                staticClass: "projects_carousel",
                attrs: { options: _vm.appsSwiperProjects }
              },
              [
                _vm._l(Math.ceil(_vm.devProjects.length / 2), function(
                  projectIndex
                ) {
                  return [
                    _c("swiper-slide", [
                      _c("div", { staticClass: "container-fluid h-100" }, [
                        _c("div", { staticClass: "row h-100" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-6 p-0" },
                            [
                              _c("z-project", {
                                attrs: {
                                  project:
                                    _vm.devProjects[(projectIndex - 1) * 2]
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.devProjects[(projectIndex - 1) * 2 + 1]
                            ? _c(
                                "div",
                                { staticClass: "col-12 col-lg-6 p-0" },
                                [
                                  _c("z-project", {
                                    attrs: {
                                      project:
                                        _vm.devProjects[
                                          (projectIndex - 1) * 2 + 1
                                        ]
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(1)
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "col-12 mt-2 mt-lg-0 col-lg-2 projects-aside py-4"
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row layout-horizontal_page title-section mb-2 mb-lg-0"
        },
        [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-8" },
            [
              _c(
                "swiper",
                {
                  ref: "carouselProjects",
                  staticClass: "projects_carousel",
                  attrs: { options: _vm.designSwiperProjects }
                },
                [
                  _vm._l(Math.ceil(_vm.designProjects.length / 2), function(
                    projectIndex
                  ) {
                    return [
                      _c("swiper-slide", [
                        _c("div", { staticClass: "container-fluid h-100" }, [
                          _c("div", { staticClass: "row h-100" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-lg-6 p-0" },
                              [
                                _c("z-project", {
                                  attrs: {
                                    project:
                                      _vm.designProjects[(projectIndex - 1) * 2]
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.designProjects[(projectIndex - 1) * 2 + 1]
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-lg-6 p-0" },
                                  [
                                    _c("z-project", {
                                      attrs: {
                                        project:
                                          _vm.designProjects[
                                            (projectIndex - 1) * 2 + 1
                                          ]
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm._m(3)
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-12 col-lg-2 d-flex justify-content-center align-items-center"
      },
      [
        _c("div", { staticClass: "title-verticalbox my-3 my-lg-0" }, [
          _c("h2", { staticClass: "title" }, [_vm._v("WEB & APPS")]),
          _vm._v(" "),
          _c("h3", { staticClass: "subtitle" }, [_vm._v("Portafolio")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vertical-navigation" }, [
      _c("div", {
        staticClass: "swiper-button-prev swiper-button-white",
        attrs: { id: "appsProjectsPrev" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-next swiper-button-white",
        attrs: { id: "appsProjectsNext" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-12 col-lg-2 d-flex justify-content-center align-items-center"
      },
      [
        _c("div", { staticClass: "title-verticalbox my-3 my-lg-0" }, [
          _c("h2", { staticClass: "title" }, [_vm._v("DISEÑO GRÁFICO")]),
          _vm._v(" "),
          _c("h3", { staticClass: "subtitle" }, [_vm._v("Portafolio")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vertical-navigation" }, [
      _c("div", {
        staticClass: "swiper-button-prev swiper-button-black",
        attrs: { id: "designProjectsPrev" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-next swiper-button-black",
        attrs: { id: "designProjectsNext" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }