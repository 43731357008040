var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-menu cursor-pointer",
      class: { "button-menu--open": _vm.isMenuOpen },
      on: { click: _vm.toggleMenu }
    },
    [_c("span", { staticClass: "button-menu_bar" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }