<template>
	<div class="layout-horizontal_wrapper container-fluid design-page">
		<z-header/>
		<div class="row layout-horizontal_page align-items-center full-height-lg">
			<div class="col-12 col-lg-6 offset-lg-1 banner-section order-lg-2 align-self-stretch">
				<img class="banner-section_image" src="@/app/imgs/pantone.png" alt="">
			</div>
			<div class="col-12 col-lg-5 order-lg-1 py-3 py-lg-0 px-lg-4 mt-3 mt-lg-0">
				<h1 class="title">DISEÑO <span>GRÁFICO</span></h1>
				<p
						class="mt-3">En Zipvisual como agencia de diseño brindamos soluciones visuales efectivas a nuestros clientes con diferentes servicios.<br><br>La imagen y estrategia de comunicación visual de tu empresa, servicio o producto es lo más importante para lograr el impacto en tus clientes.Te ayudamos a posicionar tu marca con un diseño adecuado, estético y funcional.<br><br>A continuación te presentamos algunas opciones que tenemos para ti, pero recuerda que si tienes alguna otra idea nosotros te ayudamos a realizarla!!!
				</p>
				<div class="row mt-4 d-none d-lg-block">
					<div>
						<z-begin-project/>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_page align-items-center bg-zip">
			<div class="col-12 col-lg-6 px-lg-5 py-3 py-lg-0">
				<div class="info-service">
					<span class="info-service_number">01</span>
					<h2 class="info-service_title">Diseño de Branding</h2>
					<div class="info-service_description">
						<p>Ofrecemos el servicio de branding que consiste en la creación de tu marca que puede ser desde la creación del naming hasta el desarrollo de manual de identidad coporativa.</p>
						<ul>
							<li>Naming</li>
							<li>Desarrollo de Marca</li>
							<li>Papelería Institucional</li>
							<li>Identidad Corporativa</li>
						</ul>
					</div>
				</div>
				<div class="mt-3 d-none d-lg-block">
					<div>
						<img class="" src="@/app/imgs/gar1.png" alt="">
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 px-lg-5 py-3 py-lg-0">
				<div class="mb-3 d-none d-lg-block text-center">
					<img src="@/app/imgs/gar2.png" alt="">
				</div>
				<div class="info-service">
					<span class="info-service_number">02</span>
					<h2 class="info-service_title">Diseño Editorial</h2>
					<div class="info-service_description">
						<p>Diseñamos, maquetamos y generamos una composición visual adecuada para tus publicaciones, de esta forma logramos la armonía entre texto, imagen y diagramación, la cual genera una mejor experiencia para tus lectores.</p>
						<ul>
							<li>Catálogo</li>
							<li>Revista</li>
							<li>Libro</li>
							<li>Publicaciones Digitales</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_page align-items-center bg-zip2">
			<div class="col-12 col-lg-6 px-lg-5 py-3 py-lg-0">
				<div class="info-service">
					<span class="info-service_number">03</span>
					<h2 class="info-service_title">Diseño Publicitario</h2>
					<div class="info-service_description">
						<p>La representación visual de tu empresa, producto o servicio con este tipo de medios es muy importante ya que es lo que dejará huella en tus clientes y prospectos.</p>
						<ul>
							<li>Brochure</li>
							<li>Panfleto</li>
							<li>Cartel | Parabus</li>
							<li>Flyers | Lona</li>
						</ul>
					</div>
				</div>
				<div class="d-none d-lg-block text-center">
					<img class="mt-3 mr-5" src="@/app/imgs/pencil.png" alt="">
				</div>
			</div>
			<div class="col-12 col-lg-6 px-lg-5 py-3 py-lg-0">
				<div class="d-none d-lg-block text-right">
					<img class="mb-5" src="@/app/imgs/sharpener.png" alt="">
				</div>
				<div class="info-service">
					<span class="info-service_number">04</span>
					<h2 class="info-service_title">Campaña Publicitaria</h2>
					<div class="info-service_description">
						<p>Elaboramos campañas publicitarias ya sean sociales, culturales, políticas o de promoción. Nos basamos en una planificación y una metodología específica para lograr los mejores resultados para nuestros clientes.</p>
						<ul>
							<li>Catálogo</li>
							<li>Revista</li>
							<li>Libro</li>
							<li>Publicaciones Digitales</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_page">
			<div class="col-12 col-lg-7 px-lg-5 d-flex align-items-center py-3 py-lg-0">
				<div>
					<div class="text-right d-none d-lg-block">
						<img src="@/app/imgs/icon-lent.png" alt="">
					</div>
					<div class="info-service">
						<span class="info-service_number">05</span>
						<h2 class="info-service_title">Servicios Extra</h2>
						<div class="info-service_description">
							<p>Nuestros servicios integrales nos permiten ofrecer las gestiones de impresión, además de contar con el servicio de fotografía, para de esta forma satisfacer todas las necesidades de nuestros clientes.</p>
							<ul>
								<li>Impresión</li>
								<li>Fotografía</li>
								<li>Video</li>
							</ul>
						</div>
						<div class="d-flex justify-content-center">
							<a :href="$asset('docs/brochure-zipvisual.pdf')" target="_blank">
								<button class="circle-button ml-5 brochure-button mt-lg-n2 mb-lg-6">
									<span>brochure</span>
									<z-icon icon="download"/>
								</button>
							</a>
						</div>
					</div>
				</div>
				<div class="bars-section d-none d-lg-block">
					<div class="bar bar_c"></div>
					<div class="bar bar_m"></div>
					<div class="bar bar_y"></div>
					<div class="bar bar_k"></div>
				</div>
			</div>
			<div class="col banner-section-2 d-flex justify-content-center align-items-end">
				<div class="">
					<div class="mb-5">
						<z-begin-project hide-icon/>
					</div>
				</div>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZHeader from "@/app/js/components/ZHeader";
    import ZFooter from "@/app/js/components/ZFooter";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZBeginProject from "@/app/js/components/ZBeginProject";

    export default {
        components: {ZBeginProject, ZIcon, ZFooter, ZHeader},
        metaInfo() {
            return {
				title: this.$t("Agencia de Diseño Gráfico en Guadalajara, diseñamos logotipos, marcas, catálogos, publicidad."),
				meta: [{
					vmid: 'description',
					name: 'description',
					content: this.$t("En nuestra agencia somo especialistas en creación de marcas, diseño de identidad corporativa, catálogos de productos entre otros.")
				}],
            }
        },
    }
</script>

<style scoped>

</style>
