var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "z-footer row" }, [
    _c(
      "div",
      { staticClass: "col d-flex flex-column justify-content-between" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col p-3 pt-lg-5" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v("\n\t\t\t\tTel: "),
            _c(
              "a",
              {
                staticClass: "z-footer_phone",
                attrs: { href: "tel:3327913995" }
              },
              [_vm._v("33 2791 3995")]
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: "mailto:contacto@zipvisual.com" } }, [
              _vm._v("contacto@zipvisual.com")
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled my-lg-2" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.facebook.com/zipvisual.mx/",
                      target: "_blank"
                    }
                  },
                  [_c("z-icon", { attrs: { icon: "facebook" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://x.com/zipvisual_mx",
                      target: "_blank"
                    }
                  },
                  [_c("z-icon", { attrs: { icon: "twitter" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.instagram.com/zipvisual.mx/",
                      target: "_blank"
                    }
                  },
                  [_c("z-icon", { attrs: { icon: "instagram" } })],
                  1
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row flex-column" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "col p-2 pl-3 " }, [
            _c("div", { staticClass: "z-footer_privacy" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showPrivacyAdvice($event)
                    }
                  }
                },
                [_vm._v("Aviso de privacidad")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1" }, [
                _vm._v("Copyright 2024 Zipvisual")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col z-footer_end" }, [
            _c(
              "div",
              { staticClass: "p-2 pl-3" },
              [
                _c("router-link", { attrs: { to: { name: "home" } } }, [
                  _c("img", {
                    attrs: {
                      src: require("@/app/imgs/logo-mini-zipvisual-white.png"),
                      alt: "Logo zipvisual mini"
                    }
                  })
                ])
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "z-footer_title" }, [
      _c("span", [_vm._v("Zip")]),
      _vm._v("visual")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-unstyled" }, [
      _c("li", [_vm._v("Islandia 610,")]),
      _vm._v(" "),
      _c("li", [_vm._v("Col. Revolución,")]),
      _vm._v(" "),
      _c("li", [_vm._v("CP 44400")]),
      _vm._v(" "),
      _c("li", [_vm._v("Guadalajara, Jal. MX")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col p-2 px-3" }, [
      _c("img", {
        attrs: {
          src: require("@/app/imgs/logo-paypal.png"),
          alt: "Paypal logo"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mt-2"
        },
        [
          _c("span", [
            _c("img", {
              attrs: { src: require("@/app/imgs/logo-mastercard.png"), alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("span", [
            _c("img", {
              attrs: { src: require("@/app/imgs/logo-visa.png"), alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("span", [
            _c("img", {
              attrs: {
                src: require("@/app/imgs/logo-americanexpress.png"),
                alt: ""
              }
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }