var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "row layout-horizontal_middle-page we-section justify-content-center align-items-center"
    },
    [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col px-3 px-lg-0" },
            [
              _vm.showMore
                ? _c("router-link", { attrs: { to: { name: "company" } } }, [
                    _c("button", { staticClass: "circle-button" }, [
                      _vm._v("ver más")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 p-3 p-lg-4" },
            [
              _c("h2", { staticClass: "we-section_subtitle" }, [
                _vm._v("Nuestros clientes")
              ]),
              _vm._v(" "),
              _c(
                "swiper",
                {
                  ref: "carouselClients",
                  attrs: { options: _vm.optionsSwiperClients }
                },
                _vm._l(_vm.clientSliders, function(slider) {
                  return _c(
                    "swiper-slide",
                    { key: slider.__id, staticClass: "p-1" },
                    [
                      _c("img", {
                        staticClass: "image-cover",
                        attrs: { src: _vm.$asset(slider.content.path), alt: "" }
                      })
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-lg-9 p-3 p-lg-4" }, [
      _c("h2", { staticClass: "we-section_title" }, [
        _vm._v("Apasionados "),
        _c("br"),
        _c("span", [_vm._v("de lo que hacemos...")])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "we-section_text" }, [
        _vm._v(
          "Somos una agencia de diseño gráfico, web, desarrollo y marketing digital, comprometida a crear soluciones integrales para el crecimiento de nuestros clientes."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "Buscamos la innovación constante para generar una comunicación efectiva y un resultado único y rentable para todos los clientes que nos brindan su confianza.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }