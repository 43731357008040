var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "z-shoppingcar" }, [
      _c("img", {
        staticClass: "z-shoppingcar_car",
        attrs: { src: require("@/app/imgs/car.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-shoppingcar_lines",
        attrs: { src: require("@/app/imgs/lines.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-shoppingcar_w1",
        attrs: { src: require("@/app/imgs/wheel.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-shoppingcar_w2",
        attrs: { src: require("@/app/imgs/wheel.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-shoppingcar_pointer",
        attrs: { src: require("@/app/imgs/pointer.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }