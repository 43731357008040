<template>
	<div class="layout-horizontal_wrapper container-fluid marketing-page">
		<z-header/>
		<div class="row layout-horizontal_page title-section">
			<div class="col-12 col-lg-5 justify-content-end align-items-end p-2 banner-section d-flex order-lg-2">
				<img src="@/app/imgs/icon-dartboard.png" alt="">
			</div>
			<div class="col-12 col-lg-7 d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0 order-lg-1">
				<div class="px-0 px-lg-5">
					<h1 class="title-section_title"><span
							class="title-section_title_stroked">{{$t('MARKETING')}}</span> {{$t('Digital')}}</h1>
					<p
							class="mt-3">Realizamos estrategias de Marketing Digital enfocadas al posicionamiento de la marca y la comercialización de los servicios y productos de nuestros clientes utilizando los medios digitales para generar un impulso en sus ventas.<br><br>Para las campañas de Marketing Digital utilizamos plataformas como Google Adwords, Redes Sociales como Facebook (ADS), Instagram, twitter donde ofrecemos la administración y estrategia para lograr los objetivos.<br><br>Contamos con el servicio de Posicionamiento Web (SEO) que consiste en optimizar las páginas web para que aparezcan en lo primeros lugares de los buscadores como Google, Yahoo y Bing con esto generamos mayor tráfico a los Sitios Web generando clientes potenciales.<br><br>A continuación te presentamos nuestros servicios de marketing digital que ofrecemos en nuestra agencia Zipvisual.
					</p>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page adwords-section align-items-center full-height-lg py-4 py-lg-0">
			<img class="adwords-section_icon" src="@/app/imgs/icon-awords.png" alt="">
			<div class="col">
				<div class="row">
					<div class="col-12 px-lg-4">
						<div class="info-service info-service--white">
							<span class="info-service_number">01</span>
							<h2 class="info-service_title">Google Adwords</h2>
							<div class="info-service_description">
								<p>Anúnciate en Google Adwords para aparecer en los primeros lugares y estar ahí en el momento justo en que los usuarios buscan tus productos o servicios.</p>
								<ul>
									<li>Anuncios Red de Búsqueda</li>
									<li>Anuncios Red de Display</li>
									<li>Objetivos</li>
									<li>Estrategias</li>
									<li>Keywords Relevantes</li>
									<li>Instalación y Gestión de Anuncios</li>
									<li>Call to Action</li>
									<li>Reportes y Estadísticas</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 d-flex justify-content-center justify-content-lg-end">
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page media-section align-items-center full-height-lg py-3 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 order-1 order-lg-2 pt-lg-7">
						<div class="info-service">
							<span class="info-service_number">02</span>
							<h2 class="info-service_title">Social Media</h2>
							<div class="info-service_description">
								<p>Te ayudamos a conectar con tu público por medio de las redes sociales, administramos y realizamos campañas de anuncios para atraer a clientes potenciales.</p>
								<ul>
									<li>Administración de Redes Sociales</li>
									<li>Campañas en Facebook ADS</li>
									<li>Campañas en Instagram ADS</li>
									<li>Campañas en Twitter ADS</li>
									<li>Anuncios en Youtube</li>
									<li>Desarrollo de Contenido</li>
									<li>Blogs</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 d-flex justify-content-center justify-content-lg-end order-2 pt-lg-5 order-lg-1">

					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page seo-section align-items-center full-height-lg py-3 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 px-lg-4 pb-lg-5">
						<div class="info-service">
							<span class="info-service_number">03</span>
							<h2 class="info-service_title">SEO Posicionamiento Web</h2>
							<div class="info-service_description">
								<p>Optimizamos tu página web en los motores de búsqueda como Google, Yahoo y Bing con la finalidad de aparecer en los primeros resultados de búsqueda de manera natural (orgánica).</p>
								<ul>
									<li>Análisis</li>
									<li>Objetivos</li>
									<li>Optimización de Sitios Web</li>
									<li>Seguimientos Mensual</li>
									<li>Estadísticas de Resultados</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 d-none d-lg-flex justify-content-start align-items-end">
						<img class="ml-n5 mb-n10" src="@/app/imgs/bg-seo.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page email marketing-section align-items-center full-height-lg py-3 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 order-1 order-lg-2 px-lg-4 pt-lg-6">
						<div class="info-service">
							<span class="info-service_number">04</span>
							<h2 class="info-service_title">Email Marketing</h2>
							<div class="info-service_description">
								<p>Llega a todos tus clientes con un mensaje especial en el momento justo, promociona o da a conocer a tu empresa, servicios o productos de manera masiva.</p>
								<ul class="mt-3">
									<li>Estrategia</li>
									<li>Diseño de mailing</li>
									<li>Envío de campaña</li>
									<li>Resultados</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 d-flex justify-content-center order-2 order-lg-1">
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_600-page brochure-section">
			<div class="col d-flex justify-content-center align-items-end full-height-lg py-4 py-lg-0">
				<a :href="$asset('docs/brochure-zipvisual.pdf')" target="_blank">
					<button class="circle-button circle-button--big brochure-button mb-lg-5">
						<span>brochure</span>
						<z-icon icon="download"/>
					</button>
				</a>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZFooter from "@/app/js/components/ZFooter";
    import ZHeader from "@/app/js/components/ZHeader";
    import ZCloudcomputing from "@/app/js/components/ZCloudcomputing";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZFingerprint from "@/app/js/components/ZFingerprint";
    import ZShoppingcar from "@/app/js/components/ZShoppingcar";

    export default {
        name: "HostingPage",
        components: {ZShoppingcar, ZFingerprint, ZIcon, ZCloudcomputing, ZFooter, ZHeader},
        metaInfo() {
            return {
				title: this.$t("Creamos una estrategia de Marketing digital adecuada para tu negocio con publicidad digital"),
				meta: [{
					vmid: 'description',
					name: 'description',
					content: this.$t("Implementamos la mejor estrategia para cumplir los objetivos de empresa mediante anuncios con plataformas como Facebook ads, Google ads y SEO Posicionamiento orgánico.")
				}],
            }
        },
    }
</script>

<style scoped>

</style>
