<template>
  <div class="layout-horizontal_wrapper container-fluid home-page">
    <div class="row layout-horizontal_middle-page">
      <div
        class="col logo-panel d-flex justify-content-lg-center align-items-center full-height-lg py-1 py-lg-0"
      >
        <router-link :to="{ name: 'home' }">
          <h1 class="m-0 pl-2">
            <img
              class="logo-panel_logo"
              src="@/app/imgs/logo-zipvisual-white.png"
              alt="Zipvisual logo"
              title="Zipvisual"
            />
          </h1>
        </router-link>
        <div class="logo-panel_icons d-none d-lg-flex align-self-start">
          <a href="tel:3327913995"
            ><img src="@/app/imgs/icon-circle-phone.png" alt=""
          /></a>
          <a href="mailto:contacto@zipvisual.com"
            ><img class="mt-1" src="@/app/imgs/icon-circle-envelope.png" alt=""
          /></a>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5213327913995"
            style="font-size: 54px; margin-top: -10px; margin-left: -2px"
            ><div class="z-icon z-icon--center text-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <defs></defs>
                <path
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                ></path>
              </svg></div
          ></a>
        </div>
        <z-begin-project
          class="d-none d-lg-block logo-panel_cta"
          white
          hide-icon
        />
      </div>
    </div>
    <div class="row layout-horizontal_page full-height-lg align-items-stretch">
      <div
        class="col main-banner"
        :style="{ backgroundImage: `url(${mainBanner})` }"
      >
        <div class="row full-height">
          <div class="col align-self-end pl-3 py-2">
            <h2 class="main-banner_title">{{ $t("home.design") }}</h2>
            <h2 class="main-banner_title">{{ $t("home.development") }}</h2>
            <h2 class="main-banner_title main-banner_title--stroked">
              {{ $t("home.marketing") }}
            </h2>
            <icon-scroll class="main-banner_icon d-none d-lg-block" />
          </div>
          <div class="col">
            <h2 class="main-banner_vertical-title">
              <img
                v-if="$i18n.locale === 'es'"
                src="@/app/imgs/banner-somos-zipvisual-es.png"
                alt="texto somos zipvisual"
                title="Somos Zipvisual"
              />
              <img
                v-else
                src="@/app/imgs/banner-somos-zipvisual-en.png"
                alt="text we are zipvisual"
                title="We Are Zipvisual"
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
    <z-we-section show-more />
    <div class="row layout-horizontal_page new-section full-height-lg">
      <div
        class="col-12 col-lg-6 order-lg-2 d-flex justify-content-center align-items-center"
      >
        <div class="row justify-content-center align-items-center">
          <div
            class="col col-lg-8 p-3 p-lg-3"
            data-iv="mamey"
            data-aos-anchor=".layout-horizontal_wrapper"
          >
            <h2 class="new-section_title text-center text-lg-left">
              LO MÁS <br /><span>NUEVO</span>
            </h2>
            <h3 class="new-section_subtitle text-center text-lg-left">
              - PORTAFOLIO -
            </h3>
            <p class="new-section_text">
              Desarrollamos proyectos integrales buscando la mejor solución para
              nuestros clientes.
            </p>
            <router-link :to="{ name: 'portfolio' }">
              <button class="circle-button mt-3 mt-lg-5">ver más</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 order-lg-1">
        <swiper
          :options="optionsSwiperProjects"
          class="new-section_carousel"
          ref="carouselProjects"
        >
          <template v-for="projectIndex in Math.ceil(lastProjects.length / 2)">
            <swiper-slide>
              <div class="container-fluid h-100">
                <div class="row h-100">
                  <div class="col-12 col-lg-6 p-0">
                    <z-project
                      :project="lastProjects[(projectIndex - 1) * 2]"
                    ></z-project>
                  </div>
                  <div
                    class="col-12 col-lg-6 p-0"
                    v-if="lastProjects[(projectIndex - 1) * 2 + 1]"
                  >
                    <z-project
                      :project="lastProjects[(projectIndex - 1) * 2 + 1]"
                    ></z-project>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="new-section_navigation">
          <div
            id="carouselProjectsPrev"
            class="swiper-button-prev swiper-button-black"
          ></div>
          <div
            id="carouselProjectsNext"
            class="swiper-button-next swiper-button-black"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="row layout-horizontal_page services-section full-height-lg align-items-center"
    >
      <div
        class="col-lg-2 d-none d-lg-block services-section_banner align-self-stretch"
      ></div>
      <div class="col-12 col-lg-9 px-3 pl-lg-4">
        <div class="row mt-3 mt-lg-0">
          <div class="col">
            <h2 class="services-section_title">{{ $t("Servicios") }}</h2>
            <h3 class="services-section_subtitle d-inline-block">
              {{ $t("DISEÑO INNOVACIÓN & ") }}<br />
              <span>{{ $t("ESTRATEGIA") }}</span
              ><br />
              <span>{{ $t("PARA") }}</span
              ><span>{{ $t("VENDER MÁS") }}</span>
            </h3>
            <z-begin-project class="ml-lg-3" />
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="col-12 col-lg-8">
            <swiper :options="optionsSwiperServices" ref="carouselServices">
              <swiper-slide>
                <div class="row services-section_service">
                  <div class="col-12 col-md-8">
                    <h3 class="services-section_service_title">
                      {{ $t("Diseño Gráfico") }}
                    </h3>
                    <p>
                      {{
                        $t(
                          "La imagen es fundamental para lograr el impacto en tus clientes"
                        )
                      }}
                    </p>
                    <ul>
                      <li>Branding</li>
                      <li>{{ $t("Imagen Corporativa") }}</li>
                      <li>{{ $t("Diseño Editorial") }}</li>
                      <li>{{ $t("Diseño Publicitario") }}</li>
                      <li>{{ $t("Fotografía") }}</li>
                      <li>{{ $t("Impresión") }}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-4 text-right text-lg-left">
                    <div
                      class="position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                    >
                      <img src="@/app/imgs/icon-mouse.png" alt="" />
                      <router-link :to="{ name: 'design' }">
                        <button class="circle-button position-absolute">
                          {{ $t("ver más") }}
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="row services-section_service">
                  <div class="col-12 col-md-8">
                    <h3 class="services-section_service_title">
                      {{ $t("Paginas Web, Sistemas & Apps") }}
                    </h3>
                    <p>
                      {{
                        $t(
                          "Los medios digitales fortalecen a tu empresa y la ponen al alcance de todo el mundo"
                        )
                      }}
                    </p>
                    <ul>
                      <li>{{ $t("Diseño y desarrollo de Sitios Web") }}</li>
                      <li>{{ $t("Administrador de contenidos") }}</li>
                      <li>{{ $t("Sistemas a tu medida") }}</li>
                      <li>{{ $t("Ecommerce") }}</li>
                      <li>{{ $t("Apps iOS + Android") }}</li>
                      <li>{{ $t("Consultoría Web") }}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-4 text-right text-lg-left">
                    <div
                      class="position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                    >
                      <img src="@/app/imgs/icon-cursor.png" alt="" />
                      <router-link :to="{ name: 'development' }">
                        <button class="circle-button position-absolute">
                          {{ $t("ver más") }}
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="row services-section_service">
                  <div class="col-12 col-md-8">
                    <h3 class="services-section_service_title">
                      {{ $t("Marketing Digital") }}
                    </h3>
                    <p>
                      {{
                        $t(
                          "La mejor estrategia para llegar a tu público meta e incrementar las ventas que estas buscando"
                        )
                      }}
                    </p>
                    <ul>
                      <li>{{ $t("Administración de Redes Sociales") }}</li>
                      <li>{{ $t("SEM campañas en Google Adwords") }}</li>
                      <li>{{ $t("ADS campañas en Facebook") }}</li>
                      <li>{{ $t("SEO posicionamiento web") }}</li>
                      <li>{{ $t("Publicidad en Línea") }}</li>
                      <li>{{ $t("Email Marketing") }}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-4 text-right text-lg-left">
                    <div
                      class="position-relative d-inline-block pb-4 pr-4 pt-lg-5"
                    >
                      <img src="@/app/imgs/icon-lighter.png" alt="" />
                      <router-link :to="{ name: 'marketing' }">
                        <button class="circle-button position-absolute">
                          {{ $t("ver más") }}
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div
                class="swiper-pagination"
                id="carouselServicesPagination"
                slot="pagination"
              ></div>
            </swiper>
          </div>
          <div class="col-lg-3 d-none d-lg-block">
            <ul class="list-unstyled services-section_points-list">
              <li>{{ $t("ANALISIS") }}</li>
              <li>{{ $t("OBJETIVOS") }}</li>
              <li>{{ $t("DESARROLLO") }}</li>
              <li>{{ $t("PROYECTO") }}</li>
              <li>{{ $t("SEGUIMIENTO") }}</li>
              <li>{{ $t("ÉXITO") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row layout-horizontal_page">
      <div
        class="col-12 col-lg-6 p-3 pr-lg-5 align-items-center justify-content-center d-flex contact-section"
      >
        <z-contact />
      </div>
      <div class="col-lg-6 p-3 p-lg-0 newsletter-section">
        <div
          class="row justify-content-center align-items-center full-height-lg"
        >
          <div class="col-lg-6">
            <h2 class="newsletter-section_title">Newsletter</h2>
            <h3 class="newsletter-section_subtitle">
              {{ $t("Recibe nuestros artículos y promociones") }}
            </h3>
            <z-newsletter />
          </div>
        </div>
      </div>
    </div>
    <div class="row layout-horizontal_middle-page blog-section">
      <div
        class="col p-3 p-lg-0 align-items-center justify-content-lg-center d-flex"
      >
        <div>
          <h2 class="blog-section_title">BLOG</h2>
          <a
            :href="post.link"
            target="_blank"
            class="blog-section_post mt-3"
            v-for="post in posts"
            :key="post.id"
          >
            <div class="blog-section_post_image">
              <el-image
                style="width: 270px; height: 154px"
                v-if="
                  post._embedded &&
                  post._embedded['wp:featuredmedia'] &&
                  post._embedded['wp:featuredmedia'][0] &&
                  post._embedded['wp:featuredmedia'][0]['link']
                "
                :src="post._embedded['wp:featuredmedia'][0]['link']"
                fit="cover"
              ></el-image>
            </div>

            <div class="pl-3">
              <span class="blog-section_post_date">{{
                formatDate(post.date_gmt)
              }}</span>
              <h3 class="blog-section_post_title">{{ post.title.rendered }}</h3>
              <z-icon class="blog-section_post_icon" icon="chevron-right" />
            </div>
          </a>
          <a href="https://www.zipvisual.com/blog/">
            <button class="circle-button mt-3">ver más</button>
          </a>
        </div>
      </div>
    </div>
    <z-footer />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import IconScroll from "@/app/js/components/IconScroll";
import ZIcon from "@/app/js/components/ZIcon";
import ZFooter from "@/app/js/components/ZFooter";
import ZNewsletter from "@/app/js/components/ZNewsletter";
import ZWeSection from "@/app/js/components/ZWeSection";
import ZBeginProject from "@/app/js/components/ZBeginProject";
import ZContact from "@/app/js/components/ZContact";
import ZProject from "@/app/js/components/ZProject";

export default {
  components: {
    ZProject,
    ZContact,
    ZBeginProject,
    ZWeSection,
    ZNewsletter,
    ZFooter,
    ZIcon,
    IconScroll,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      posts: [],
    };
  },
  metaInfo() {
    return {
      title: this.$t(
        "Agencia de Diseño gráfico y Desarrollo de páginas web en Guadalajara 👨💻."
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t(
            "Diseñamos páginas web a la medida y desarrollamos proyectos de Diseño gráfico de alto nivel, cuidando al máximo la identidad visual de nuestros proyectos."
          ),
        },
      ],
    };
  },
  computed: {
    mainBanner() {
      return this.$asset(this.$store.state.banners.banners.path);
    },
    lastProjects() {
      return this.$store.getters["projects/lastProjects"];
    },
    optionsSwiperProjects() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: "#carouselProjectsNext",
          prevEl: "#carouselProjectsPrev",
        },
        breakpoints: {
          // when window width is >= 768px
          992: {
            direction: "vertical",
            slidesPerView: 3,
          },
        },
      };
    },
    optionsSwiperServices() {
      return {
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
          el: "#carouselServicesPagination",
          clickable: true,
        },
        effect: "fade",
      };
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    loadPosts() {
      $.get(
        "https://www.zipvisual.com/blog/wp-json/wp/v2/posts?_embed&per_page=3"
      ).done((res) => {
        this.posts = res;
      });
    },
  },
  created() {
    this.loadPosts();
  },
};
</script>
