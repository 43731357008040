var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid marketing-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page title-section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-7 d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0 order-lg-1"
          },
          [
            _c("div", { staticClass: "px-0 px-lg-5" }, [
              _c("h1", { staticClass: "title-section_title" }, [
                _c("span", { staticClass: "title-section_title_stroked" }, [
                  _vm._v(_vm._s(_vm.$t("MARKETING")))
                ]),
                _vm._v(" " + _vm._s(_vm.$t("Digital")))
              ]),
              _vm._v(" "),
              _vm._m(1)
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row layout-horizontal_600-page brochure-section" },
        [
          _c(
            "div",
            {
              staticClass:
                "col d-flex justify-content-center align-items-end full-height-lg py-4 py-lg-0"
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$asset("docs/brochure-zipvisual.pdf"),
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "circle-button circle-button--big brochure-button mb-lg-5"
                    },
                    [
                      _c("span", [_vm._v("brochure")]),
                      _vm._v(" "),
                      _c("z-icon", { attrs: { icon: "download" } })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-12 col-lg-5 justify-content-end align-items-end p-2 banner-section d-flex order-lg-2"
      },
      [
        _c("img", {
          attrs: { src: require("@/app/imgs/icon-dartboard.png"), alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3" }, [
      _vm._v(
        "Realizamos estrategias de Marketing Digital enfocadas al posicionamiento de la marca y la comercialización de los servicios y productos de nuestros clientes utilizando los medios digitales para generar un impulso en sus ventas."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "Para las campañas de Marketing Digital utilizamos plataformas como Google Adwords, Redes Sociales como Facebook (ADS), Instagram, twitter donde ofrecemos la administración y estrategia para lograr los objetivos."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "Contamos con el servicio de Posicionamiento Web (SEO) que consiste en optimizar las páginas web para que aparezcan en lo primeros lugares de los buscadores como Google, Yahoo y Bing con esto generamos mayor tráfico a los Sitios Web generando clientes potenciales."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "A continuación te presentamos nuestros servicios de marketing digital que ofrecemos en nuestra agencia Zipvisual.\n\t\t\t\t"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row layout-horizontal_middle-page adwords-section align-items-center full-height-lg py-4 py-lg-0"
      },
      [
        _c("img", {
          staticClass: "adwords-section_icon",
          attrs: { src: require("@/app/imgs/icon-awords.png"), alt: "" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 px-lg-4" }, [
              _c("div", { staticClass: "info-service info-service--white" }, [
                _c("span", { staticClass: "info-service_number" }, [
                  _vm._v("01")
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "info-service_title" }, [
                  _vm._v("Google Adwords")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-service_description" }, [
                  _c("p", [
                    _vm._v(
                      "Anúnciate en Google Adwords para aparecer en los primeros lugares y estar ahí en el momento justo en que los usuarios buscan tus productos o servicios."
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [_vm._v("Anuncios Red de Búsqueda")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Anuncios Red de Display")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Objetivos")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Estrategias")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Keywords Relevantes")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Instalación y Gestión de Anuncios")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Call to Action")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Reportes y Estadísticas")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "col-lg-12 d-flex justify-content-center justify-content-lg-end"
            })
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row layout-horizontal_middle-page media-section align-items-center full-height-lg py-3 py-lg-0"
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 order-1 order-lg-2 pt-lg-7" }, [
              _c("div", { staticClass: "info-service" }, [
                _c("span", { staticClass: "info-service_number" }, [
                  _vm._v("02")
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "info-service_title" }, [
                  _vm._v("Social Media")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-service_description" }, [
                  _c("p", [
                    _vm._v(
                      "Te ayudamos a conectar con tu público por medio de las redes sociales, administramos y realizamos campañas de anuncios para atraer a clientes potenciales."
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [_vm._v("Administración de Redes Sociales")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Campañas en Facebook ADS")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Campañas en Instagram ADS")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Campañas en Twitter ADS")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Anuncios en Youtube")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Desarrollo de Contenido")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Blogs")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "col-lg-12 d-flex justify-content-center justify-content-lg-end order-2 pt-lg-5 order-lg-1"
            })
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row layout-horizontal_middle-page seo-section align-items-center full-height-lg py-3 py-lg-0"
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 px-lg-4 pb-lg-5" }, [
              _c("div", { staticClass: "info-service" }, [
                _c("span", { staticClass: "info-service_number" }, [
                  _vm._v("03")
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "info-service_title" }, [
                  _vm._v("SEO Posicionamiento Web")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-service_description" }, [
                  _c("p", [
                    _vm._v(
                      "Optimizamos tu página web en los motores de búsqueda como Google, Yahoo y Bing con la finalidad de aparecer en los primeros resultados de búsqueda de manera natural (orgánica)."
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [_vm._v("Análisis")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Objetivos")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Optimización de Sitios Web")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Seguimientos Mensual")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Estadísticas de Resultados")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-lg-12 d-none d-lg-flex justify-content-start align-items-end"
              },
              [
                _c("img", {
                  staticClass: "ml-n5 mb-n10",
                  attrs: { src: require("@/app/imgs/bg-seo.png"), alt: "" }
                })
              ]
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row layout-horizontal_middle-page email marketing-section align-items-center full-height-lg py-3 py-lg-0"
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 order-1 order-lg-2 px-lg-4 pt-lg-6" },
              [
                _c("div", { staticClass: "info-service" }, [
                  _c("span", { staticClass: "info-service_number" }, [
                    _vm._v("04")
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "info-service_title" }, [
                    _vm._v("Email Marketing")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-service_description" }, [
                    _c("p", [
                      _vm._v(
                        "Llega a todos tus clientes con un mensaje especial en el momento justo, promociona o da a conocer a tu empresa, servicios o productos de manera masiva."
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "mt-3" }, [
                      _c("li", [_vm._v("Estrategia")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Diseño de mailing")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Envío de campaña")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Resultados")])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "col-lg-12 d-flex justify-content-center order-2 order-lg-1"
            })
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }