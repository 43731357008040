<template>
	<div class="k-scalable-content" ref="el">
		<div class="k-scalable-content_container" ref="container" :style="styles">
			<slot ref="slot"></slot>
		</div>
	</div>
</template>

<script>
    export default {
        name: "KScalableContent",
        data() {
            return {
                styles: null
            }
        },
        methods: {
            getScale() {
                let widthScale = this.$refs.el.offsetWidth / this.$refs.container.offsetWidth;
                let heightScale = this.$refs.el.offsetHeight / this.$refs.container.offsetHeight;
                return Math.min(widthScale, heightScale);
            },
            updateScale() {
                console.log(this.$refs.el.offsetWidth, this.$refs.container.offsetWidth);
                this.styles = {
                    transform: `scale(${this.getScale()})`
                };
            }
        },
        mounted() {
            this.updateScale();
            window.onload = () => {
                this.updateScale();
                window.addEventListener("resize", this.updateScale)
            }
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.updateScale);
        }
    }
</script>

<style scoped>

</style>