<template>
	<div class="layout-horizontal_wrapper container-fluid contact-page">
		<z-header/>
		<div class="row layout-horizontal_page p-3 p-lg-0">
			<div class="col-12 col-lg-6 align-items-center justify-content-center d-flex">
				<z-contact/>
			</div>
			<div class="col-lg-6 newsletter-section mt-5 mt-lg-0">
				<div class="row justify-content-center align-items-center full-height-lg">
					<div class="col-lg-6">
						<h2 class="newsletter-section_title">Newsletter</h2>
						<h3 class="newsletter-section_subtitle">{{$t("Recibe nuestros artículos y promociones")}}</h3>
						<z-newsletter/>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_page begin-section full-height-lg align-items-center">
			<div class="col-12 col-lg-3 p-3 mt-3 text-center text-lg-left">
				<z-icon class="begin-section_icon" icon="filled-circle-phone"/>
				<h2 class="begin-section_title mt-3 mb-0">Comenzamos</h2>
				<a class="begin-section_phone d-block" href="tel:3327913995">3327913995</a>
				<z-icon class="begin-section_icon mt-3" icon="filled-circle-envelope"/>
				<a class="begin-section_email d-block mt-3"
				   href="mailto:contacto@zipvisual.com">contacto@zipvisual.com</a>
			</div>
			<div class="col full-height-lg map-section">
				<z-gmap class="map-section_map"/>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZHeader from "@/app/js/components/ZHeader";
    import ZContact from "@/app/js/components/ZContact";
    import ZNewsletter from "@/app/js/components/ZNewsletter";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZFooter from "@/app/js/components/ZFooter";
    import ZGmap from "@/app/js/components/ZGmap";

    export default {
        components: {ZGmap, ZFooter, ZIcon, ZNewsletter, ZContact, ZHeader},
        head() {
            return {
                title: 'Zipvisual - Contacto',
            }
        }
    }
</script>

<style scoped>

</style>
