import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'es',
	fallbackLocale: 'es',
	messages: {
		es: require('./es_locales.json'),
		en: require('./en_locales.json')
	}
});
