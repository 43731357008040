var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "el", staticClass: "k-scalable-content" }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass: "k-scalable-content_container",
        style: _vm.styles
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }