var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row z-header flex-column justify-content-between" },
    [
      _c(
        "div",
        {
          staticClass:
            "col d-flex justify-content-lg-center align-items-center align-items-lg-start p-2 "
        },
        [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: { name: "home" } } }, [
                _c("img", {
                  staticClass: "z-header_logo",
                  attrs: {
                    src: require("@/app/imgs/logo-zipvisual-grey.png"),
                    alt: ""
                  }
                })
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col d-none d-lg-flex z-header_icons flex-column justify-content-end"
        },
        [
          _c(
            "a",
            { attrs: { href: "tel:3327913995" } },
            [_c("z-icon", { attrs: { icon: "circle-phone", center: "" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "mailto:contacto@zipvisual.com" } },
            [
              _c("z-icon", {
                staticClass: "mt-1",
                attrs: { icon: "circle-envelope", center: "" }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }