<template>
	<div @click="toggleMenu" class="button-menu cursor-pointer" :class="{'button-menu--open':isMenuOpen}">
		<span class="button-menu_bar"></span>
	</div>
</template>

<script>
	export default {
		name: "ButtonMenu",
		computed: {
			isMenuOpen() {
				return this.$store.state.app.isMenuOpen
			}
		},
		methods: {
			toggleMenu() {
				this.$store.commit('app/TOGGLE_MENU')
			}
		}
	}
</script>
