var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "z-contact row"
    },
    [
      _c(
        "div",
        { staticClass: "col-12 col-lg" },
        [
          _c("h2", { staticClass: "z-contact_title" }, [_vm._v("Contacto")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "mt-4",
              attrs: { model: _vm.form, "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name", rules: _vm.rules.required } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "100",
                      placeholder: "Nombre completo*"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "email",
                    rules: _vm.rules.required.concat(_vm.rules.email)
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", placeholder: "Email*" },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "phone", rules: _vm.rules.required } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "15",
                      placeholder: "Móvil / Teléfono  con lada*"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "company" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", placeholder: "Empresa" },
                    model: {
                      value: _vm.form.company,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "company", $$v)
                      },
                      expression: "form.company"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Asunto de interés", prop: "interests" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.interests,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "interests", $$v)
                        },
                        expression: "form.interests"
                      }
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { value: "design", label: "Diseño gráfico" }
                      }),
                      _vm._v(" "),
                      _c("el-checkbox", {
                        attrs: {
                          value: "development",
                          label: "Páginas web, sistemas y apps"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-checkbox", {
                        attrs: {
                          value: "marketing",
                          label: "Marketing digital"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "comments", rules: _vm.rules.required } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 3 },
                      maxlength: "500",
                      "show-word-limit": "",
                      type: "textarea",
                      placeholder: "Cuéntanos tu proyecto*"
                    },
                    model: {
                      value: _vm.form.comments,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "comments", $$v)
                      },
                      expression: "form.comments"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto pl-2 align-self-end" }, [
        _c(
          "button",
          { staticClass: "circle-button", on: { click: _vm.sendForm } },
          [_vm._v("enviar")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }