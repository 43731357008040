var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Aviso de privacidad",
        visible: _vm.isPrivacyAdviceVisible,
        "before-close": _vm.updateCloseState
      }
    },
    [
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO I. RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES."
          ),
          _c("br"),
          _vm._v("\n\t\t\tRICARDO LARA MILLÁN")
        ]),
        _vm._v(
          ", persona física, con capacidad legal, propietario de la negociación conocida como\n\t\t"
        ),
        _c("b", [_vm._v("ZIPVISUAL")]),
        _vm._v(
          ",\n\t\tubicada en el número 610 de la calle Islandia, Colonia Revolución, Código Postal 44400, en Guadalajara, Jalisco; es\n\t\tresponsable del uso y protección de datos personales en cumplimiento con los artículos 15, 16, 17 y demás relativos\n\t\ta la Ley Federal de Protección de Datos Personales en Posesión de Particulares y de la legislación aplicable. Por\n\t\tlo que hace de su conocimiento la finalidad del tratamiento de sus datos personales, los medios que limiten su\n\t\tdivulgación, los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los mismos, así como en su\n\t\tcaso, la transferencia éstos, la revocación de su consentimiento para el tratamiento y el procedimiento aplicable\n\t\tsegún corresponda.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("CAPITULO II. ENCARGADO Y LÍDER DE PRIVACIDAD.")]),
        _c("br"),
        _vm._v(
          "\n\t\tPara información y todo lo relacionado con el tratamiento y protección de sus datos personales usted podrá\n\t\tcontactarse con el encargado de privacidad, "
        ),
        _c("b", [_vm._v("C. RICARDO LARA MILLÁN")]),
        _vm._v(
          ", a través del correo electrónico:\n\t\tcontacto@zipvisual.com, siguiendo con los procedimientos que se establecen en el presente aviso de privacidad.\n\t\tSeñalando a su vez, como domicilio para oír y recibir todo tipo de notificaciones la finca marcada con el número\n\t\t610 de la calle Islandia, Colonia Revolución, Código Postal 44400, en Guadalajara, Jalisco.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO III. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES"
          )
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tLos datos personales que recabe de usted, los utilizaré para las siguientes finalidades:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v("Envío de información en relación a productos y servicios;")
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Cotizaciones;")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Proveer, gestionar, solicitar e informar acerca de los servicios contratados;"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas\n\t\t\tde cualquier relación jurídica y comercial que establezcamos con motivo de la compra de nuestros productos y\n\t\t\tservicios.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas\n\t\t\tde cualquier relación jurídica y comercial que establezcamos con motivo de suministro hacia mí, venta de productos\n\t\t\tde interés de la empresa, así como ofrecimiento y prestación de productos y servicios con los mismos fines.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("Registrar sus datos a nuestro sistema de información.")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Almacenar sus datos personales en nuestros directorios, bases de datos correspondientes y expedientes con fines\n\t\t\tadministrativos, contables, legales y fiscales.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Utilizar sus datos personales en contratos comerciales, así como en los diversos instrumentos jurídicos que\n\t\t\tamparen la relación comercial que hayamos contraído.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Facturación y expedición de documentos con sus datos personales en cumplimiento de las obligaciones\n\t\t\tcomerciales.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Dar seguimiento al proceso de cobranza y atención al cliente."
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Otorgamiento o solicitud de garantías derivadas de nuestra relación comercial."
          )
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Devolución de mercancías.")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "En su caso, para los procesos administrativos, judiciales y/o jurisdiccionales que involucren al titular de los\n\t\t\tdatos personales, derivado del incumplimiento de obligaciones de contraídas en la relación comercial.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Para identificarlo y permitirle el acceso a nuestras instalaciones."
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Video-vigilancia dentro de nuestras instalaciones, como por ejemplo el uso de circuitos cerrados de televisión,\n\t\t\tasí como cámaras de video, por lo que usted, por el hecho de ingresar a nuestras instalaciones, está aceptando\n\t\t\ttácitamente su consentimiento para que su imagen y voz sean monitoreados e incluso grabados, para los fines ya\n\t\t\tmencionados.\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "NOTA: Es importante que usted tenga en cuenta que en caso de no contar con sus datos personales no estaría en\n\t\tposibilidad de llevar a cabo los fines para los cuales se requieren y en tal caso no tendría ningún tipo de\n\t\tresponsabilidad para la debida consecución de los mismos."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "De manera adicional, utilizaré su información personal para las siguientes finalidades secundarias, las cuales\n\t\t"
        ),
        _c("b", [_vm._v("no\n\t\t\tson necesarias")]),
        _vm._v(
          " para el servicio solicitado, pero que me permiten y facilitan brindarle una mejor atención:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "Comunicaciones con fines de mercadotecnia, darle a conocer campañas publicitarias, así como promociones,\n\t\t\tproductos y servicios nuevos y nuestra participación en redes sociales.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Realización de encuestas, estadísticas y estudios de mercado."
          )
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Invitación a eventos.")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Y en general para las actividades encaminadas a promover, mantener, mejorar y evaluar nuestros productos y\n\t\t\tservicios.\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Si usted no está de acuerdo para que sus datos personales sean tratados para las finalidades secundarias\n\t\tseñaladas, puede negarme su consentimiento desde este momento, enviando un correo electrónico a\n\t\tcontacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o bien, presentándola de\n\t\tmanera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400, en Guadalajara,\n\t\tJalisco; con atención al líder de privacidad, en el que especifique la o las finalidades secundarias para las que\n\t\tno desea que utilicemos sus datos personales. Su negativa en este sentido, no afectará el ejercicio de los derechos\n\t\ty cumplimiento de las obligaciones que demande la relación comercial que hayamos contraído, lo anterior de\n\t\tconformidad a lo establecido en las fracciones ll y lll del artículo 16 de la Ley Federal de Protección de Datos\n\t\tPersonales en Posesión de Particulares."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v("CAPITULO IV. FORMA DE OBTENCIÓN DE LOS DATOS PERSONALES")
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tPuedo obtener información a través de los siguientes medios:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v("Cuando me la proporciona directamente por escrito;")
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("En forma verbal;")]),
        _vm._v(" "),
        _c("li", [_vm._v("Correo electrónico o formularios de Internet.")]),
        _vm._v(" "),
        _c("li", [_vm._v("Medios telefónicos;")]),
        _vm._v(" "),
        _c("li", [_vm._v("Cualquier otra forma permitida por la ley.")])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("CAPITULO V. DATOS PERSONALES QUE SE RECABAN")]),
        _c("br"),
        _vm._v(
          "\n\t\tPara llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaré los siguientes datos\n\t\tpersonales:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Nombre Completo y/o razón social, Nacionalidad, Clave Única de Registro de Población (CURP), Registro\n\t\t\tFederal de Contribuyentes (RFC), Lugar y fecha de Nacimiento, Sexo, Estado Civil, Edad, Estatura, Peso, Domicilio\n\t\t\tparticular, domicilio comercial, número de teléfono comercial, Número de teléfono particular; Número de celular,\n\t\t\tpagina Web, Correo electrónico, Firma autógrafa, Fotografía, Huella dactilar.\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n\t\tAdemás de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de\n\t\tprivacidad, requiero los siguientes, los cuales se consideran sensibles, incluyendo los datos personales\n\t\tfinancieros y patrimoniales, mismos que requieren protección especial, y en los cuales para su tratamiento\n\t\tsolicitaré me otorgue su consentimiento previamente de manera expresa y por escrito, de conformidad al artículo 9 y\n\t\t16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Financieros: Historial crediticio en lo general, estados financieros, libros contables, títulos accionarios.\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("Patrimoniales: Bienes Patrimoniales en general que posee.")
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO VI. MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE SUS DATOS PERSONALES Y MEDIOS PARA LIMITAR EL USO Y/O\n\t\t\tDIVULGACIÓN DE LOS MISMOS."
          )
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tLe garantizo que, su información personal, será resguardada bajo estricta confidencialidad, y para prevenir\n\t\trazonablemente el uso o divulgación indebida de la misma, he implementado medidas de seguridad físicas, técnicas,\n\t\tadministrativas y legales de conformidad con la normatividad aplicable. En particular, cuento , políticas de\n\t\tprivacidad, así como cursos de capacitación a mis empleados encargados de los mismos, tenemos el acceso restringido\n\t\ta información personal (sólo trabajadores autorizados), cuento con un concentrado de datos personales en bases de\n\t\tdatos en las que trabajamos bajo seguridad informática (debidamente clasificados por categoría de datos); así como\n\t\tpersonal responsable del análisis de tratamiento y riesgo de los mismos, y cláusulas contractuales que atienden a\n\t\teste punto, como las penalidades en caso de violación de éstas, en todos nuestros contratos laborales en toda la\n\t\testructura legal de la empresa, lo anterior en cumplimiento del artículo 14 de la Ley Federal de Protección de\n\t\tDatos Personales en Posesión de Particulares. "
        ),
        _c("br"),
        _vm._v(
          "\n\t\tCabe mencionar que dentro del presente aviso de privacidad le establezco en cada capítulo correspondiente, los\n\t\tmedios en los que podrá ejercer sus derechos conforme a la ley y el procedimiento para concretar los mismos; en\n\t\tcaso de requerir información adicional podrá solicitarla directamente con el líder de privacidad en los medios ya\n\t\testablecidos.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO VII. DERECHOS ARCO Y DE REVOCACIÓN DEL CONSENTIMIENTO DEL TITULAR DE LOS DATOS PERSONALES."
          )
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tEn los términos permitidos por la legislación vigente aplicable, el titular de los datos personales tiene el\n\t\tderecho de solicitar el acceso, rectificación, cancelación y oposición (ARCO) de los mismos:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "El titular de los datos personales tiene derecho de conocer qué datos personales tenemos de usted, para qué los\n\t\t\tutilizamos y las condiciones de uso que les damos (ACCESO).\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Solicitar la corrección de su información personal en caso que esté desactualizada, sea inexacta, incompleta o\n\t\t\terrónea (RECTIFICACION).\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Solicitar la eliminación y no tratamiento de sus datos personales cuando considere que los mismos no están\n\t\t\tsiendo utilizados conforme a las disposiciones previstas en la normatividad aplicable, salvo los casos previstos\n\t\t\ten el artículo 26 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (CANCELACION).\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "El titular tendrá derecho en todo momento y por causa legítima a oponerse al tratamiento de sus datos\n\t\t\tpersonales (OPOSICION).\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lo anterior con fundamento en los artículos 23, 24, 25 y 27 de la Ley Federal de Protección de Datos Personales\n\t\ten Posesión de Particulares. "
        ),
        _c("br"),
        _vm._v(
          "\n\t\tUsted puede revocar el consentimiento que, en su caso, me haya otorgado para el tratamiento de sus datos\n\t\tpersonales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podré atender su solicitud o\n\t\tconcluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiera seguir tratando sus\n\t\tdatos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento\n\t\timplicará que no le pueda seguir prestando el servicio que me solicitó o la conclusión de su relación conmigo.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO VIII. DE LOS MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RATIFICACIÓN, CANCELACIÓN U OPOSICIÓN (ARCO)\n\t\t\tDE LOS DATOS PERSONALES."
          )
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tEl titular podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus\n\t\tdatos, respecto al capítulo V, presentando una solicitud de conformidad con el siguiente procedimiento: "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n\t\tRealizar su solicitud enviando un correo electrónico con el asunto “EJERCICIO DE LOS DERECHOS DE ARCO” a la\n\t\tsiguiente dirección: contacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o\n\t\tbien, presentándola de manera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal\n\t\t44400, en Guadalajara, Jalisco; dirigido al C. RICARDO LARA MILLÁN, en su calidad de encargado de privacidad para\n\t\tatender sus solicitudes.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "El nombre del titular de los datos personales, su domicilio y un medio alterno para comunicarle la respuesta a\n\t\t\tsu solicitud;\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Los documentos que acrediten la identidad del titular de los datos personales o, en su caso, si lo hace un\n\t\t\ttercero acreditar la representación legal;\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los\n\t\t\tderechos de ARCO; y\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cualquier otro elemento o documento que facilite la localización de sus Datos Personales; y"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "En su caso, las solicitudes de rectificación de datos personales, el titular deberá indicar las modificaciones\n\t\t\ta realizarse y aportar la documentación que sustente su petición.\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "En caso de que se aprecie que la documentación tenga tachaduras, este alterada o se tenga duda razonable de ser\n\t\tapócrifa, que no sea el titular, o no ser el legítimo representante, el que suscribe RICARDO LARA MILLÁN, me\n\t\treservo el derecho a solicitar cualquier otro documento o acción para comprobar y acreditar la titularidad de los\n\t\tdatos, por lo que se le podrá solicitar que presente la documentación en original o copias certificadas en la\n\t\toficina del responsable. "
        ),
        _c("br"),
        _vm._v(
          "\n\t\tSi faltara alguna información en su solicitud se le solicitará subsane la omisión a la brevedad."
        ),
        _c("br"),
        _vm._v(
          "\n\t\tSi cumpliera con todas las formalidades requeridas para dicho trámite se dará respuesta en un plazo máximo de\n\t\tveinte días, contados a partir de la fecha en que se recibió su petición o a partir de la fecha en que haya\n\t\tsubsanado en su totalidad las prevenciones. El plazo antes referido podrá ser ampliado una sola vez por un periodo\n\t\tigual, siempre y cuando así lo justifiquen las circunstancias del caso. De conformidad con el artículo 32 de la ley\n\t\tde la materia.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO IX. MEDIOS PARA EJERCER SU DERECHO DE REVOCACIÓN DEL CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS\n\t\tPERSONALES"
          )
        ]),
        _c("br"),
        _vm._v(
          "\n\t\tPara revocar el consentimiento que me ha otorgado deberá enviar un correo electrónico a contacto@zipvisual.com o\n\t\tmediante Carta por escrito enviada vía Correo Postal, mensajería o bien, presentándola de manera personal al\n\t\tdomicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400, en el municipio de Guadalajara,\n\t\tJalisco; con el asunto\n\t\t"
        ),
        _c("b", [_vm._v("“REVOCACIÓN DE CONSENTIMIENTO”")]),
        _vm._v(
          " y cumpliendo con los requisitos establecidos en el\n\t\tprocedimiento del capítulo VI se ARCO señalado en el capítulo VIIl de este aviso de privacidad.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("CAPITULO X. TRANSFERENCIA DE DATOS PERSONALES")]),
        _c("br"),
        _vm._v("\n\t\tHago de su conocimiento que\n\t\t"),
        _c("b", [_vm._v("NO REALIZO TRANSFERENCIAS")]),
        _vm._v(" de información a terceros sin su previa autorización."),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n\t\tUsted puede manifestar su negativa para la transferencia de la que requiero su consentimiento, enviando un correo\n\t\telectrónico a contacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o bien,\n\t\tpresentándola de manera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400,\n\t\ten Guadalajara, Jalisco, si no lo hace, entenderé que me ha otorgado su consentimiento."
        ),
        _c("br"),
        _vm._v(
          "\n\t\tSolo se podrán hacer transferencias sin su consentimiento de acuerdo a lo previsto en el artículo 37 de la Ley\n\t\tFederal de Protección de datos Personales en Posesión de los Particulares, así como a realizar esta transferencia\n\t\ten los términos que fija esa ley, a lo que establecemos lo supuesto:\n\t"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Cuando la transferencia de datos personales esté prevista en una Ley o Tratado en los que México sea parte;"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia de datos personales esté prevista en una Ley;"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control\n\t\t\tcomún del responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo del responsable que opere\n\t\t\tbajo los mismos procesos y políticas internas;\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del\n\t\t\ttitular, por el responsable y un tercero;\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la\n\t\t\tprocuración o administración de justicia;\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso\n\t\t\tjudicial; y\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el\n\t\t\tresponsable y el titular.\n\t\t"
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "CAPITULO XI. CAMBIOS AL AVISO DE PRIVACIDAD Y PROCEDIMIENTO PARA DARLO A CONOCER A LOS TITULARES DE LOS DATOS\n\t\t\tPERSONALES."
          )
        ]),
        _c("br"),
        _vm._v("\n\t\tEl que suscribe\n\t\t"),
        _c("b", [_vm._v("RICARDO LARA MILLÁN")]),
        _vm._v(
          ", podré modificar y/o actualizar el presente aviso de privacidad, y le\n\t\tinformaré\n\t\tal respecto de cualquier cambio de los referidos a través del portal Web: www.zipvisual.com, mismo que se pone a su\n\t\tentera disposición para dicho fin, el cual se encuentra disponible las 24 horas del día y los 365 días del año.\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("CAPITULO XII. UTILIZACIÓN DE COOKIES Y WEB BEACONS")]),
        _c("br"),
        _vm._v(
          "\n\t\tSe hace de su conocimiento que las cookies son archivos de texto que son descargados automáticamente y almacenados\n\t\ten el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten\n\t\trecordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la\n\t\tvisualización de las páginas en ese servidor, nombre y contraseña."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n\t\tLe informamos que utilizamos cookies propias y de tercero técnico, personalizado y de análisis para determinar sus\n\t\tpreferencias y mejorar nuestro servicio. Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer\n\t\tcómo hacerlo, consulte:"
        ),
        _c("br"),
        _vm._v(" "),
        _c("a", { attrs: { href: "http://bit.ly/1jqKOFl" } }, [
          _vm._v("http://bit.ly/1jqKOFl")
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "http://bit.ly/1lpLCNQ" } }, [
          _vm._v("http://bit.ly/1lpLCNQ")
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "http://mzl.la/1JGJ5aS" } }, [
          _vm._v("http://mzl.la/1JGJ5aS")
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "http://apple.co/1zYNGVH" } }, [
          _vm._v("http://apple.co/1zYNGVH")
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("CAPITULO XIII. CONSENTIMIENTO")]),
        _c("br"),
        _vm._v(
          "\n\t\tLe informo que previamente a que recabe sus datos personales, los considerados sensibles, así como los financieros\n\t\ty patrimoniales conforme el presente aviso de privacidad, le solicitaré me otorgue su consentimiento expreso y por\n\t\tescrito de conformidad a la Ley Federal de Protección de Datos Personales en Posesión de Particulares.\n\t"
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.updateCloseState } }, [
            _vm._v("Aceptar")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }