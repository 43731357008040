<template>
	<div class="z-begin-now">
		<router-link :to="{name:'contact'}">
			<div class="d-inline-block" :class="{'z-begin-now--white':white}">
				{{$t("¡Iniciemos tu proyecto!")}}<br>
				<button class="z-begin-now_button">{{$t("¡COMENZAR AHORA!")}}</button>
			</div>
		</router-link>
		<z-icon v-if="!hideIcon" icon="pixel-cursor-pointer" class="z-begin-now_icon"/>
	</div>
</template>

<script>
    import ZIcon from "@/app/js/components/ZIcon";

    export default {
        name: "ZBeginProject",
        components: {ZIcon},
        props: {
            white: Boolean,
            hideIcon: Boolean
        }
    }
</script>

<style scoped>

</style>
