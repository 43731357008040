var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Solicitud de Cotización",
        visible: _vm.isRequestServiceVisible,
        "before-close": _vm.updateCloseState
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          staticClass: "mt-4",
          attrs: { model: _vm.form, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name", rules: _vm.rules.required } },
            [
              _c("el-input", {
                attrs: { maxlength: "100", placeholder: "Nombre completo*" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "email",
                rules: _vm.rules.required.concat(_vm.rules.email)
              }
            },
            [
              _c("el-input", {
                attrs: { maxlength: "100", placeholder: "Email*" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "phone", rules: _vm.rules.required } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "15",
                  placeholder: "Móvil / Teléfono  con lada*"
                },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "company" } },
            [
              _c("el-input", {
                attrs: { maxlength: "100", placeholder: "Empresa" },
                model: {
                  value: _vm.form.company,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.servicesRequest && _vm.servicesRequest.domains.length
            ? _c("div", { staticClass: "pl-2" }, [
                _c("h6", [_vm._v("Dominios a solicitar")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.servicesRequest.domains, function(domain) {
                    return _c("li", [_vm._v(_vm._s(domain.domain))])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.servicesRequest && _vm.servicesRequest.hosting !== "none"
            ? _c("div", { staticClass: "pl-2" }, [
                _c("h6", [_vm._v("Hospedaje")]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.servicesRequest.hosting))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "comments" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 3 },
                  maxlength: "500",
                  "show-word-limit": "",
                  type: "textarea",
                  placeholder: "Comentarios"
                },
                model: {
                  value: _vm.form.comments,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "comments", $$v)
                  },
                  expression: "form.comments"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.updateCloseState }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.sendForm } },
                [_vm._v("Aceptar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }