<template>
  <div class="row layout-horizontal_middle-page we-section justify-content-center align-items-center">
    <div class="col">
      <div class="row align-items-center">
        <div class="col-12 col-lg-9 p-3 p-lg-4">
          <h2 class="we-section_title">Apasionados <br><span>de lo que hacemos...</span></h2>
          <p class="we-section_text">Somos una agencia de diseño gráfico, web, desarrollo y marketing digital, comprometida a crear soluciones integrales para el crecimiento de nuestros clientes.<br><br>Buscamos la innovación constante para generar una comunicación efectiva y un resultado único y rentable para todos los clientes que nos brindan su confianza.
          </p>
        </div>
        <div class="col px-3 px-lg-0">
          <router-link v-if="showMore" :to="{name: 'company'}">
            <button class="circle-button">ver más</button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-3 p-lg-4">
          <h2 class="we-section_subtitle">Nuestros clientes</h2>
          <swiper :options="optionsSwiperClients" ref="carouselClients">
            <swiper-slide class="p-1" v-for="slider in clientSliders" :key="slider.__id">
              <img class="image-cover"
                   :src="$asset(slider.content.path)"
                   alt="">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

  export default {
    name: "ZWeSection",
    components: {Swiper, SwiperSlide},
    props: {
      showMore: Boolean
    },
    computed: {
      optionsSwiperClients() {
        return {
          slidesPerView: 1,
          loop: true,
          speed: 5000,
          autoplay: {
            delay: 0,
            disableOnInteraction: false,
          },
          pagination: {
            el: this.$refs.carouselClientsPagination,
            clickable: true
          },
          breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
            }
          }
        }
      },
      clientSliders() {
        return this.$store.state.clients.clients;
      }
    }
  }
</script>
