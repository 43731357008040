var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "z-fingerprint" }, [
      _c("img", {
        attrs: { src: require("../../imgs/fingerprint.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-fingerprint_c1",
        attrs: { src: require("../../imgs/fp-circle-1.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "z-fingerprint_c2",
        attrs: { src: require("../../imgs/fp-circle-2.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }