<template>
	<div class="row z-header flex-column justify-content-between">
		<div class="col d-flex justify-content-lg-center align-items-center align-items-lg-start p-2 ">
			<div>
				<router-link :to="{name:'home'}">
					<img class="z-header_logo" src="@/app/imgs/logo-zipvisual-grey.png" alt="">
				</router-link>
			</div>
		</div>
		<div class="col d-none d-lg-flex z-header_icons flex-column justify-content-end">
			<a href="tel:3327913995">
				<z-icon icon="circle-phone" center/>
			</a>
			<a href="mailto:contacto@zipvisual.com">
				<z-icon class="mt-1" icon="circle-envelope" center/>
			</a>
		</div>
	</div>
</template>

<script>
	import ZIcon from "@/app/js/components/ZIcon";

	export default {
		name: "ZHeader",
		components: {ZIcon},
		beforeMount(){
			let body = document.querySelector('body');
			body.classList.add('z-header_bodycontainer');
		},
		beforeDestroy(){
			let body = document.querySelector('body');
			body.classList.remove('z-header_bodycontainer');
		}
	}
</script>

<style scoped>

</style>
