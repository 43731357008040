<template>
	<div class="layout-horizontal_wrapper container-fluid portfolio-page">
		<z-header/>
		<div class="row layout-horizontal_page title-section">
			<div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
				<div class="title-verticalbox my-3 my-lg-0">
					<h2 class="title">WEB & APPS</h2>
					<h3 class="subtitle">Portafolio</h3>
				</div>
			</div>
			<div class="col-12 col-lg-8 px-lg-0">
				<swiper :options="appsSwiperProjects" class="projects_carousel" ref="carouselProjects">
					<template v-for="projectIndex in Math.ceil(devProjects.length/2)">
						<swiper-slide>
							<div class="container-fluid h-100">
								<div class="row h-100">
									<div class="col-12 col-lg-6 p-0">
										<z-project :project="devProjects[((projectIndex-1)*2)]"></z-project>
									</div>
									<div class="col-12 col-lg-6 p-0" v-if="devProjects[((projectIndex-1)*2)+1]">
										<z-project :project="devProjects[((projectIndex-1)*2)+1]"></z-project>
									</div>
								</div>
							</div>
						</swiper-slide>
					</template>
				</swiper>
				<div class="vertical-navigation">
					<div id="appsProjectsPrev" class="swiper-button-prev swiper-button-white"></div>
					<div id="appsProjectsNext" class="swiper-button-next swiper-button-white"></div>
				</div>
			</div>
			<div class="col-12 mt-2 mt-lg-0 col-lg-2 projects-aside py-4"></div>
		</div>
		<div class="row layout-horizontal_page title-section mb-2 mb-lg-0">
			<div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
				<div class="title-verticalbox my-3 my-lg-0">
					<h2 class="title">DISEÑO GRÁFICO</h2>
					<h3 class="subtitle">Portafolio</h3>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<swiper :options="designSwiperProjects" class="projects_carousel" ref="carouselProjects">
					<template v-for="projectIndex in Math.ceil(designProjects.length/2)">
						<swiper-slide>
							<div class="container-fluid h-100">
								<div class="row h-100">
									<div class="col-12 col-lg-6 p-0">
										<z-project :project="designProjects[((projectIndex-1)*2)]"></z-project>
									</div>
									<div class="col-12 col-lg-6 p-0" v-if="designProjects[((projectIndex-1)*2)+1]">
										<z-project :project="designProjects[((projectIndex-1)*2)+1]"></z-project>
									</div>
								</div>
							</div>
						</swiper-slide>
					</template>
				</swiper>
				<div class="vertical-navigation">
					<div id="designProjectsPrev" class="swiper-button-prev swiper-button-black"></div>
					<div id="designProjectsNext" class="swiper-button-next swiper-button-black"></div>
				</div>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZHeader from "@/app/js/components/ZHeader";
    import {SwiperSlide, Swiper} from 'vue-awesome-swiper'
    import ZFooter from "@/app/js/components/ZFooter";
    import ZProject from "@/app/js/components/ZProject";
    import ZProjectViewer from "@/app/js/components/ZProjectViewer";

    export default {
        name: "HostingPage",
        components: {ZProjectViewer, ZProject, ZFooter, ZHeader, SwiperSlide, Swiper},
        metaInfo() {
            return {
				title: this.$t("Conoce nuestros proyectos de creación de páginas web, diseño de logos y más"),
				meta: [{
					vmid: 'description',
					name: 'description',
					content: this.$t("Te presentamos nuestros trabajos realizados de Diseño gráfico y Desarrollo web para diferentes empresas, conoce lo que podemos hacer por tu negocio")
				}],
            }
        },
        computed: {
            appsSwiperProjects() {
                return {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: {
                        nextEl: "#appsProjectsNext",
                        prevEl: "#appsProjectsPrev",
                    },
                    breakpoints: {
                        // when window width is >= 768px
                        992: {
                            direction: 'vertical',
                            slidesPerView: 3,
                        }
                    }
                }
            },
            designSwiperProjects() {
                return {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: {
                        nextEl: "#designProjectsNext",
                        prevEl: "#designProjectsPrev",
                    },
                    breakpoints: {
                        // when window width is >= 768px
                        992: {
                            direction: 'vertical',
                            slidesPerView: 3,
                        }
                    }
                }
            },
            devProjects() {
                return this.$store.getters['projects/devProjects']
            },
            designProjects() {
                return this.$store.getters['projects/designProjects']
            }
        },
        data() {
            return {
                projectSelected: null
            }
        }
    }
</script>

<style scoped>

</style>
