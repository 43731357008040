export const state = () => ({
    projects: STATE.projects,
});

export const actions = {
    async loadProjects({commit}) {
        let projects = (await this.$axios.$get(`${ASSET_PATH}api/projects`)).data;
        commit('SET_PROJECTS', {});
    },
};

export const mutations = {
    SET_PROJECTS(state, payload) {
        state.projects = payload;
    }
};

export const getters = {
    designProjects(state) {
        return state.projects.filter(project => project.type === 'PROJECT_TYPE_DESIGN')
    },
    devProjects(state) {
        return state.projects.filter(project => project.type === 'PROJECT_TYPE_DEVELOPMENT')
    },
    lastProjects(state) {
        return state.projects.slice(0, 16);
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}