var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid design-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_page align-items-center full-height-lg"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-5 order-lg-1 py-3 py-lg-0 px-lg-4 mt-3 mt-lg-0"
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4 d-none d-lg-block" }, [
                _c("div", [_c("z-begin-project")], 1)
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("div", { staticClass: "row layout-horizontal_page" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-7 px-lg-5 d-flex align-items-center py-3 py-lg-0"
          },
          [
            _c("div", [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "info-service" }, [
                _c("span", { staticClass: "info-service_number" }, [
                  _vm._v("05")
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "info-service_title" }, [
                  _vm._v("Servicios Extra")
                ]),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$asset("docs/brochure-zipvisual.pdf"),
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "circle-button ml-5 brochure-button mt-lg-n2 mb-lg-6"
                        },
                        [
                          _c("span", [_vm._v("brochure")]),
                          _vm._v(" "),
                          _c("z-icon", { attrs: { icon: "download" } })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(7)
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col banner-section-2 d-flex justify-content-center align-items-end"
          },
          [
            _c("div", {}, [
              _c(
                "div",
                { staticClass: "mb-5" },
                [_c("z-begin-project", { attrs: { "hide-icon": "" } })],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-12 col-lg-6 offset-lg-1 banner-section order-lg-2 align-self-stretch"
      },
      [
        _c("img", {
          staticClass: "banner-section_image",
          attrs: { src: require("@/app/imgs/pantone.png"), alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title" }, [
      _vm._v("DISEÑO "),
      _c("span", [_vm._v("GRÁFICO")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3" }, [
      _vm._v(
        "En Zipvisual como agencia de diseño brindamos soluciones visuales efectivas a nuestros clientes con diferentes servicios."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "La imagen y estrategia de comunicación visual de tu empresa, servicio o producto es lo más importante para lograr el impacto en tus clientes.Te ayudamos a posicionar tu marca con un diseño adecuado, estético y funcional."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "A continuación te presentamos algunas opciones que tenemos para ti, pero recuerda que si tienes alguna otra idea nosotros te ayudamos a realizarla!!!\n\t\t\t"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row layout-horizontal_page align-items-center bg-zip" },
      [
        _c("div", { staticClass: "col-12 col-lg-6 px-lg-5 py-3 py-lg-0" }, [
          _c("div", { staticClass: "info-service" }, [
            _c("span", { staticClass: "info-service_number" }, [_vm._v("01")]),
            _vm._v(" "),
            _c("h2", { staticClass: "info-service_title" }, [
              _vm._v("Diseño de Branding")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-service_description" }, [
              _c("p", [
                _vm._v(
                  "Ofrecemos el servicio de branding que consiste en la creación de tu marca que puede ser desde la creación del naming hasta el desarrollo de manual de identidad coporativa."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Naming")]),
                _vm._v(" "),
                _c("li", [_vm._v("Desarrollo de Marca")]),
                _vm._v(" "),
                _c("li", [_vm._v("Papelería Institucional")]),
                _vm._v(" "),
                _c("li", [_vm._v("Identidad Corporativa")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3 d-none d-lg-block" }, [
            _c("div", [
              _c("img", {
                attrs: { src: require("@/app/imgs/gar1.png"), alt: "" }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-6 px-lg-5 py-3 py-lg-0" }, [
          _c("div", { staticClass: "mb-3 d-none d-lg-block text-center" }, [
            _c("img", {
              attrs: { src: require("@/app/imgs/gar2.png"), alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-service" }, [
            _c("span", { staticClass: "info-service_number" }, [_vm._v("02")]),
            _vm._v(" "),
            _c("h2", { staticClass: "info-service_title" }, [
              _vm._v("Diseño Editorial")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-service_description" }, [
              _c("p", [
                _vm._v(
                  "Diseñamos, maquetamos y generamos una composición visual adecuada para tus publicaciones, de esta forma logramos la armonía entre texto, imagen y diagramación, la cual genera una mejor experiencia para tus lectores."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Catálogo")]),
                _vm._v(" "),
                _c("li", [_vm._v("Revista")]),
                _vm._v(" "),
                _c("li", [_vm._v("Libro")]),
                _vm._v(" "),
                _c("li", [_vm._v("Publicaciones Digitales")])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row layout-horizontal_page align-items-center bg-zip2" },
      [
        _c("div", { staticClass: "col-12 col-lg-6 px-lg-5 py-3 py-lg-0" }, [
          _c("div", { staticClass: "info-service" }, [
            _c("span", { staticClass: "info-service_number" }, [_vm._v("03")]),
            _vm._v(" "),
            _c("h2", { staticClass: "info-service_title" }, [
              _vm._v("Diseño Publicitario")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-service_description" }, [
              _c("p", [
                _vm._v(
                  "La representación visual de tu empresa, producto o servicio con este tipo de medios es muy importante ya que es lo que dejará huella en tus clientes y prospectos."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Brochure")]),
                _vm._v(" "),
                _c("li", [_vm._v("Panfleto")]),
                _vm._v(" "),
                _c("li", [_vm._v("Cartel | Parabus")]),
                _vm._v(" "),
                _c("li", [_vm._v("Flyers | Lona")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-none d-lg-block text-center" }, [
            _c("img", {
              staticClass: "mt-3 mr-5",
              attrs: { src: require("@/app/imgs/pencil.png"), alt: "" }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-6 px-lg-5 py-3 py-lg-0" }, [
          _c("div", { staticClass: "d-none d-lg-block text-right" }, [
            _c("img", {
              staticClass: "mb-5",
              attrs: { src: require("@/app/imgs/sharpener.png"), alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-service" }, [
            _c("span", { staticClass: "info-service_number" }, [_vm._v("04")]),
            _vm._v(" "),
            _c("h2", { staticClass: "info-service_title" }, [
              _vm._v("Campaña Publicitaria")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-service_description" }, [
              _c("p", [
                _vm._v(
                  "Elaboramos campañas publicitarias ya sean sociales, culturales, políticas o de promoción. Nos basamos en una planificación y una metodología específica para lograr los mejores resultados para nuestros clientes."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Catálogo")]),
                _vm._v(" "),
                _c("li", [_vm._v("Revista")]),
                _vm._v(" "),
                _c("li", [_vm._v("Libro")]),
                _vm._v(" "),
                _c("li", [_vm._v("Publicaciones Digitales")])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right d-none d-lg-block" }, [
      _c("img", {
        attrs: { src: require("@/app/imgs/icon-lent.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-service_description" }, [
      _c("p", [
        _vm._v(
          "Nuestros servicios integrales nos permiten ofrecer las gestiones de impresión, además de contar con el servicio de fotografía, para de esta forma satisfacer todas las necesidades de nuestros clientes."
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("Impresión")]),
        _vm._v(" "),
        _c("li", [_vm._v("Fotografía")]),
        _vm._v(" "),
        _c("li", [_vm._v("Video")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bars-section d-none d-lg-block" }, [
      _c("div", { staticClass: "bar bar_c" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar bar_m" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar bar_y" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar bar_k" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }