<template>
	<div class="z-footer row">
		<div class="col d-flex flex-column justify-content-between">
			<div class="row">
				<div class="col p-3 pt-lg-5">
					<h2 class="z-footer_title"><span>Zip</span>visual</h2>
					<ul class="list-unstyled">
						<li>Islandia 610,</li>
						<li>Col. Revolución,</li>
						<li>CP 44400</li>
						<li>Guadalajara, Jal. MX</li>
					</ul>
					Tel: <a class="z-footer_phone" href="tel:3327913995">33 2791 3995</a>
					<a href="mailto:contacto@zipvisual.com">contacto@zipvisual.com</a>

					<ul class="list-unstyled my-lg-2">
						<li><a href="https://www.facebook.com/zipvisual.mx/" target="_blank">
							<z-icon icon="facebook"/>
						</a></li>
						<li><a href="https://x.com/zipvisual_mx" target="_blank">
							<z-icon icon="twitter"/>
						</a></li>
						<li><a href="https://www.instagram.com/zipvisual.mx/" target="_blank">
							<z-icon icon="instagram"/>
						</a></li>
					</ul>
				</div>
			</div>
			<div class="row flex-column">
				<div class="col p-2 px-3">
					<img src="@/app/imgs/logo-paypal.png" alt="Paypal logo">
					<div class="d-flex justify-content-between align-items-center mt-2">
						<span><img src="@/app/imgs/logo-mastercard.png" alt=""></span>
						<span><img src="@/app/imgs/logo-visa.png" alt=""></span>
						<span><img src="@/app/imgs/logo-americanexpress.png" alt=""></span>
					</div>
				</div>
				<div class="col p-2 pl-3 ">
					<div class="z-footer_privacy">
						<a href="#" @click.prevent="showPrivacyAdvice">Aviso de privacidad</a>
						<div class="mt-1">Copyright 2024 Zipvisual</div>
					</div>
				</div>
				<div class="col z-footer_end">
					<div class="p-2 pl-3">
						<router-link :to="{name:'home'}">
							<img src="@/app/imgs/logo-mini-zipvisual-white.png" alt="Logo zipvisual mini">
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import ZIcon from "@/app/js/components/ZIcon";

    export default {
        name: "ZFooter",
        components: {ZIcon},
        methods: {
            showPrivacyAdvice() {
                this.$store.commit("app/TOGGLE_PRIVACY_ADVICE");
            }
        }
    }
</script>
