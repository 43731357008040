<template>
	<div class="layout-horizontal">
		<button-menu/>
		<z-menu/>
		<z-request-service></z-request-service>
		<z-privacy-advice></z-privacy-advice>
		<z-project-viewer></z-project-viewer>
		<div class="layout-horizontal_container">
			<k-transition-page>
				<router-view/>
			</k-transition-page>
		</div>
	</div>
</template>

<script>
    import ZMenu from "@/app/js/components/ZMenu";
    import ButtonMenu from "@/app/js/components/ButtonMenu";
    import ZRequestService from "@/app/js/components/ZRequestService";
    import ZPrivacyAdvice from "@/app/js/components/ZPrivacyAdvice";
    import ZProjectViewer from "@/app/js/components/ZProjectViewer";
    import KTransitionPage from "@/app/js/components/KTransitionPage";

    export default {
        name: "FrontLayout",
        components: {KTransitionPage, ZProjectViewer, ZPrivacyAdvice, ZRequestService, ButtonMenu, ZMenu},
        beforeRouteUpdate(to, from, next) {
            const lang = to.params.lang;
            console.log(this.$i18n.locale, lang);
            if (!['en', 'es'].includes(lang)) return next('es');
            if (this.$i18n.locale !== lang) {
                this.$i18n.locale = lang
            }
            return next();
        },
    }
</script>

<style scoped>

</style>
