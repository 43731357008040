<template>
	<div class="z-contact row" v-loading="loading">
		<div class="col-12 col-lg">
			<h2 class="z-contact_title">Contacto</h2>
			<el-form ref="form" :model="form" class="mt-4" label-position="top">
				<el-form-item prop="name" :rules="rules.required">
					<el-input maxlength="100" v-model="form.name" placeholder="Nombre completo*"></el-input>
				</el-form-item>
				<el-form-item prop="email" :rules="[...rules.required,...rules.email]">
					<el-input maxlength="100" v-model="form.email" placeholder="Email*"></el-input>
				</el-form-item>
				<el-form-item prop="phone" :rules="rules.required">
					<el-input maxlength="15" v-model="form.phone" placeholder="Móvil / Teléfono  con lada*"></el-input>
				</el-form-item>
				<el-form-item prop="company">
					<el-input maxlength="100" v-model="form.company" placeholder="Empresa"></el-input>
				</el-form-item>
				<el-form-item label="Asunto de interés" prop="interests">
					<el-checkbox-group v-model="form.interests">
						<el-checkbox value="design" label="Diseño gráfico"></el-checkbox>
						<el-checkbox value="development" label="Páginas web, sistemas y apps"></el-checkbox>
						<el-checkbox value="marketing" label="Marketing digital"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item prop="comments" :rules="rules.required">
					<el-input v-model="form.comments"
							  :autosize="{ minRows: 2, maxRows: 3}"
							  maxlength="500"
							  show-word-limit
							  type="textarea"
							  placeholder="Cuéntanos tu proyecto*"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="col-auto pl-2 align-self-end">
			<button class="circle-button" @click="sendForm">enviar</button>
		</div>
	</div>
</template>

<script>
    const FormModel = {
        interests: []
    };

    export default {
        name: "ZContact",
        computed: {
            rules() {
                return {
                    email: [{type: "email", trigger: 'blur', message: 'Ingrese un email válido.'}],
                    required: [{required: true, trigger: 'blur', message: 'Este campo es requerido.'}]
                }
            }
        },
        data() {
            return {
                form: {...FormModel},
                loading: false
            }
        },
        methods: {
            async sendForm() {
                this.$refs['form'].validate(async (isValid) => {
                    if (isValid) {
                        this.loading = true;
                        $.post(LINKS.contactForm, this.form)
                            .done(() => {
                                this.$notify({
                                    title: 'Éxito',
                                    message: 'Se ha enviado el formulario, en breve nos pondremos en contacto muchas gracias.',
                                    type: 'success'
                                });

                                this.form = {...FormModel};
                            })
                            .fail((e) => {
                                console.error(e);
                                this.$notify({
                                    title: 'Error',
                                    message: 'Se ha producido un error al enviar el formulario, porfavor intente más tarde.',
                                    type: 'error'
                                })
                            })
                            .always(() => {
                                this.loading = false;
                            })
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
