var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "el-form",
        { ref: "newsletterForm", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name", rules: _vm.rules.required } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nombre completo*" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "email",
                rules: _vm.rules.required.concat(_vm.rules.email)
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Email*" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "circle-button circle-button--small",
          on: { click: _vm.sendForm }
        },
        [_c("z-icon", { attrs: { icon: "chevron-right", center: false } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }