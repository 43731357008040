<template>
	<div class="layout-horizontal_wrapper container-fluid hosting-page">
		<z-header/>
		<div class="row layout-horizontal_middle-page title-section justify-content-center align-items-center full-height-lg py-1 py-lg-0">
			<div class="col px-2 px-lg-4">
				<h1 class="title-section_title"><span
						class="title-section_title_stroked">{{$t('DOMINIOS')}}</span> {{$t('HOSPEDAJE')}}</h1>
				<p>En nuestra agencia de diseño web en Guadalajara también contamos con el servicio de registro de dominios y planes de hospedaje.<br><br>Ofrecemos este servicio para facilitarle a nuestros clientes el proceso de compra de dominios y elección de planes de hospedaje.<br><br>Contamos con varios planes y paquetes para todo tipo de proyectos.
				</p>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page domains-availability-section justify-content-center align-items-center full-height-lg py-3 py-lg-0">
			<div class="col px-2 px-lg-5">
				<p>Escribe el nombre que deseas registrar, elije su terminación y <br class="d-none d-lg-block">
					te mostraremos las opciones disponibles.</p>
				<div>
					<el-form ref="domainForm" :model="domainForm" class="domains-availability-section_form"
							 @submit.prevent.native>
						www.
						<el-form-item class="domains-availability-section_form_name" prop="name" :rules="[...rules.required]">
							<el-input v-model="domainForm.name" v-loading="loadingDomains"></el-input>
						</el-form-item>
						<el-form-item class="ml-1 domains-availability-section_form_extension" prop="extension" :rules="[...rules.required]">
							<el-select v-model="domainForm.extension">
								<el-option v-for="extension in domainExtensions"
										   :key="extension.id"
										   :value="extension.extension"
										   :label="`.${extension.extension}`">
								</el-option>
							</el-select>
						</el-form-item>
						<button class="circle-button d-inline-flex ml-sm-4" @click="checkDomainAvailability">
							buscar
						</button>
					</el-form>
				</div>
				<el-collapse-transition>
					<div class="pl-lg-4" v-if="domainRequested">
						<h3 class="mb-0">Dominio</h3>
						<table class="domains-availability-section_table table table-responsive">
							<tbody>
							<tr>
								<td>{{domainRequested.domain}}</td>
								<td class="domains-availability-section_table_status"
									v-if="domainRequested.isAvailable">disponible
								</td>
								<td class="domains-availability-section_table_status domains-availability-section_table_status--unavailable"
									v-else>no disponible
								</td>
							</tr>
							</tbody>
						</table>
						<div class="container-fluid">
							<div class="row mt-3">
								<div class="col-12 px-0 pb-3">
									<span v-if="otherDomainsAvailable.length">Extensiones disponibles (Agregar)</span>
									<span v-else>Lo sentimos, no encontramos otras opciones disponibles :(</span>
								</div>
								<el-checkbox-group v-model="servicesRequest.domains">
									<div class="col-3" v-for="domain in otherDomainsAvailable">
										<el-checkbox :label="domain">{{`.${domain.extension}`}}</el-checkbox>
									</div>
								</el-checkbox-group>
							</div>
						</div>
						<el-collapse-transition>
							<div class="domains-availability-section_price py-3 mt-3" v-if="domainInversion">
								<h3 class="domains-availability-section_price_title mb-0">Inversión Anual</h3>
								<span class="domains-availability-section_price_number">{{formatPrice(domainInversion)}} pesos (más IVA)</span>
							</div>
						</el-collapse-transition>
						<div class="mt-3">
							<el-checkbox-group v-model="servicesRequest.domains" class="d-inline-block"
											   v-if="domainRequested.isAvailable">
								<el-checkbox class="domains-availability-section_cb"
											 :label="domainRequested">Solicitar el registro
								</el-checkbox>
							</el-checkbox-group>
							<button class="domains-availability-section_arrow-btn ml-lg-3"
									@click="goToHosting()">
								<z-icon icon="arrow"/>
							</button>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page domains-section justify-content-center align-items-center full-height-lg py-3 py-lg-0">
			<div class="col px-2 px-lg-4">
				<h2 class="domains-section_title">DOMINIOS <span><z-icon icon="world"></z-icon></span></h2>
				<table class="domains-section_table">
					<thead>
					<tr>
						<td>TERMINACIÓN</td>
						<td>Inversión Anual</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="extension in domainExtensions" :key="extension.id">
						<td>.{{extension.extension}}</td>
						<td>{{formatPrice(extension.pricing)}}</td>
					</tr>
					</tbody>
				</table>
				<p class="domains-section_terms mt-3">*Cualquier otra terminación favor de ponerse en contacto@zipvisual.com
					<br>
					*No se incluyen dominios Premium<br>
					*Dominios sujetos a disponibilidad<br>
					*Precios anuales más IVA<br>
					*La consulta de disponibilidad puede no ser exacta<br>
				</p>
				<el-collapse-transition>
					<div class="domains-section_requested mt-2 mt-lg-5" v-if="servicesRequest.domains.length">
						<h3 class="domains-section_requested_title">Dominio(s) Solicitado</h3>
						<div class="domains-section_requested_domains">
							<div class="row" v-for="domain in servicesRequest.domains">
								<div class="col-auto domains-section_requested_domains_remove"><i
										@click="removeDomainFromRequired(domain)"
										class="el-icon-close"></i></div>
								<div class="col">{{domain.domain}}</div>
								<div class="col">{{formatPrice(getDomainExtensionPrice(domain.extension))}}</div>
							</div>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<div class="row hosting-section align-items-center full-height-lg py-3 py-lg-0">
			<div class="col px-2 px-lg-4">
				<div class="row">
					<div class="col">
						<h2 class="hosting-section_title">Hospedaje <span><z-icon icon="hosting"></z-icon></span></h2>
						<p>Contrata un plan de hospedaje</p>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start">
						<div class="hosting-plan">
							<div class="hosting-plan_header py-2">
								<h3 class="hosting-plan_header_title">MICRO<span>ZIP</span></h3>
								<img class="hosting-plan_image" src="../../imgs/server.png" alt="">
								<h3 class="hosting-plan_size mt-3">1 GB</h3>
							</div>
							<div class="hosting-plan_footer py-2">
								<el-radio class="custom-radio" v-model="servicesRequest.hosting"
										  label="MICROZIP">$990.00
								</el-radio>
							</div>
							<div class="hosting-plan_included mt-2">
								<p>
									20 GB Transferencia mensual <br>
									Cuentas de correo<br>
									BD MySql<br>
									Webmail
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start">
						<div class="hosting-plan">
							<div class="hosting-plan_header py-2">
								<h3 class="hosting-plan_header_title">PYME<span>ZIP</span></h3>
								<img class="hosting-plan_image" src="../../imgs/server.png" alt="">
								<h3 class="hosting-plan_size mt-3">3 GB</h3>
							</div>
							<div class="hosting-plan_footer py-2">
								<el-radio class="custom-radio" v-model="servicesRequest.hosting"
										  label="PYMEZIP">$2,990.00
								</el-radio>
							</div>
							<div class="hosting-plan_included mt-2">
								<p>
									40 GB Transferencia mensual <br>
									Cuentas de correo<br>
									BD MySql<br>
									Webmail
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start">
						<div class="hosting-plan">
							<div class="hosting-plan_header py-2">
								<h3 class="hosting-plan_header_title">PRO<span>ZIP</span></h3>
								<img class="hosting-plan_image" src="../../imgs/server.png" alt="">
								<h3 class="hosting-plan_size mt-3">5 GB</h3>
							</div>
							<div class="hosting-plan_footer py-2">
								<el-radio class="custom-radio" v-model="servicesRequest.hosting"
										  label="PROZIP">$4,990.00
								</el-radio>
							</div>
							<div class="hosting-plan_included mt-2">
								<p>
									70 GB Transferencia mensual <br>
									Cuentas de correo<br>
									BD MySql<br>
									Webmail
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start">
						<div class="hosting-plan">
							<div class="hosting-plan_header py-2">
								<h3 class="hosting-plan_header_title">MASTER<span>ZIP</span></h3>
								<img class="hosting-plan_image" src="../../imgs/server.png" alt="">
								<h3 class="hosting-plan_size mt-3">10 GB</h3>
							</div>
							<div class="hosting-plan_footer py-2">
								<el-radio class="custom-radio" v-model="servicesRequest.hosting"
										  label="MASTERZIP">$9,990.00
								</el-radio>
							</div>
							<div class="hosting-plan_included mt-2">
								<p>
									70 GB Transferencia mensual <br>
									Cuentas de correo<br>
									BD MySql<br>
									Webmail
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4 justify-content-center justify-content-lg-between">
					<div class="col-12 col-sm">
						<el-radio class="custom-radio hosting-section_last-radio" v-model="servicesRequest.hosting"
								  label="none">NO deseo hospedaje
						</el-radio>
						<p class="hosting-section_terms">
							*Si requiere un servicio especial favor de ponerse en <a
								href="mailto:contacto@zipvisual.com">contacto@zipvisual.com</a><br>
							*Precios anuales más IVA
						</p>
					</div>
					<div class="col-auto pt-3 pt-sm-0">
						<transition name="el-fade-in-linear">
							<button v-if="servicesRequest.domains.length || servicesRequest.hosting !== 'none'"
									@click="showRequestServiceDialog"
									class="hosting-section_btn mr-lg-5">SOLICITAR SERVICIOS
							</button>
						</transition>
					</div>
				</div>
				<div class="row justify-content-center justify-content-lg-start">
					<div class="col-auto">
						<a :href="$asset('docs/politicas.pdf')" target="_blank"
						   class="hosting-section_terms_download d-flex mt-3">
							<div>
								DESCARGAR POLITICAS EN <br>
								SOPORTE DE DOMINIOS Y <br>
								HOSPEDAJES
							</div>
							<div class="pl-2">
								<z-icon class="hosting-section_terms_download_icon" icon="pdf-file"></z-icon>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZFooter from "@/app/js/components/ZFooter";
    import ZHeader from "@/app/js/components/ZHeader";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZRequestService from "@/app/js/components/ZRequestService";

    export default {
        name: "HostingPage",
        components: {ZRequestService, ZIcon, ZFooter, ZHeader},
        metaInfo() {
            return {
                title: this.$t("Zipvisual - Dominios y hospedajes"),
            }
        },
        computed: {
            domainInversion() {
                if (this.servicesRequest.domains.length) {
                    let total = 0;
                    this.servicesRequest.domains.forEach(dom => {
                        total += this.getDomainExtensionPrice(dom.extension);
                    });
                    return total;
                }
                return 0;
            },
            otherDomainsAvailable() {
                if (this.otherDomains) {
                    return this.otherDomains.filter(dom => dom.isAvailable);
                }
            },
            domainExtensions() {
                return this.$store.state.domainExtensions.domainExtensions
            },
            rules() {
                return {
                    required: [{required: true, trigger: 'blur', message: 'Este campo es requerido.'}]
                }
            },
        },
        data() {
            return {
                domainForm: {
                    name: "",
                    extension: "com"
                },
                servicesRequest: {
                    domains: [],
                    hosting: 'none'
                },
                otherDomains: [],
                domainRequested: null,
                requestDomain: false,
                loadingDomains: false,
                isRequestServiceDialogVisible: false
            }
        },
        methods: {
            showRequestServiceDialog() {
                this.$store.commit('app/SET_SERVICES_REQUEST', this.servicesRequest);
                this.$store.commit('app/TOGGLE_REQUEST_SERVICE');
            },
            removeDomainFromRequired(domain) {
                this.servicesRequest.domains = this.servicesRequest.domains.filter(dom => dom.domain !== domain.domain);
            },
            getDomainExtensionPrice(domainExtension) {
                let domainInfo = this.domainExtensions.find(dom => dom.extension === domainExtension);
                return domainInfo.pricing;
            },
            goToHosting() {
                let currentScroll = $('.layout-horizontal_container').scrollTop();
                let hostingOffset = $(".hosting-section").offset().left + $(".hosting-section").offset().top - 80;
                $('html, body, .layout-horizontal_container').animate({scrollTop: currentScroll + hostingOffset}, 1000);
            },
            formatPrice(price) {
                return `$${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            },
            async checkDomainAvailability() {
                this.$refs['domainForm'].validate(async (isValid) => {
                    if (isValid) {
                        this.loadingDomains = true;
                        $.get(LINKS.domainAvailability, this.domainForm)
                            .done((res) => {
                                console.log(res);
                                this.domainRequested = res.data.find(dom => dom.isRequested);
                                this.otherDomains = res.data.filter(dom => !dom.isRequested);
                            })
                            .always(() => {
                                this.loadingDomains = false;
                            });
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
