<template>
	<div class="z-phone">
		<img class="z-phone_phone" src="@/app/imgs/phone.png" alt="">
		<img class="z-phone_sun" src="@/app/imgs/sun-icon.png" alt="">
	</div>
</template>

<script>
    export default {
        name: "ZPhone"
    }
</script>

<style scoped>

</style>
