var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-horizontal_wrapper container-fluid hosting-page" },
    [
      _c("z-header"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page title-section justify-content-center align-items-center full-height-lg py-1 py-lg-0"
        },
        [
          _c("div", { staticClass: "col px-2 px-lg-4" }, [
            _c("h1", { staticClass: "title-section_title" }, [
              _c("span", { staticClass: "title-section_title_stroked" }, [
                _vm._v(_vm._s(_vm.$t("DOMINIOS")))
              ]),
              _vm._v(" " + _vm._s(_vm.$t("HOSPEDAJE")))
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page domains-availability-section justify-content-center align-items-center full-height-lg py-3 py-lg-0"
        },
        [
          _c(
            "div",
            { staticClass: "col px-2 px-lg-5" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "domainForm",
                      staticClass: "domains-availability-section_form",
                      attrs: { model: _vm.domainForm },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _vm._v("\n\t\t\t\t\twww.\n\t\t\t\t\t"),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "domains-availability-section_form_name",
                          attrs: {
                            prop: "name",
                            rules: [].concat(_vm.rules.required)
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingDomains,
                                expression: "loadingDomains"
                              }
                            ],
                            model: {
                              value: _vm.domainForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.domainForm, "name", $$v)
                              },
                              expression: "domainForm.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass:
                            "ml-1 domains-availability-section_form_extension",
                          attrs: {
                            prop: "extension",
                            rules: [].concat(_vm.rules.required)
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.domainForm.extension,
                                callback: function($$v) {
                                  _vm.$set(_vm.domainForm, "extension", $$v)
                                },
                                expression: "domainForm.extension"
                              }
                            },
                            _vm._l(_vm.domainExtensions, function(extension) {
                              return _c("el-option", {
                                key: extension.id,
                                attrs: {
                                  value: extension.extension,
                                  label: "." + extension.extension
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "circle-button d-inline-flex ml-sm-4",
                          on: { click: _vm.checkDomainAvailability }
                        },
                        [_vm._v("\n\t\t\t\t\t\tbuscar\n\t\t\t\t\t")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-collapse-transition", [
                _vm.domainRequested
                  ? _c(
                      "div",
                      { staticClass: "pl-lg-4" },
                      [
                        _c("h3", { staticClass: "mb-0" }, [_vm._v("Dominio")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass:
                              "domains-availability-section_table table table-responsive"
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.domainRequested.domain))
                                ]),
                                _vm._v(" "),
                                _vm.domainRequested.isAvailable
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "domains-availability-section_table_status"
                                      },
                                      [_vm._v("disponible\n\t\t\t\t\t\t\t")]
                                    )
                                  : _c(
                                      "td",
                                      {
                                        staticClass:
                                          "domains-availability-section_table_status domains-availability-section_table_status--unavailable"
                                      },
                                      [_vm._v("no disponible\n\t\t\t\t\t\t\t")]
                                    )
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "container-fluid" }, [
                          _c(
                            "div",
                            { staticClass: "row mt-3" },
                            [
                              _c("div", { staticClass: "col-12 px-0 pb-3" }, [
                                _vm.otherDomainsAvailable.length
                                  ? _c("span", [
                                      _vm._v(
                                        "Extensiones disponibles (Agregar)"
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "Lo sentimos, no encontramos otras opciones disponibles :("
                                      )
                                    ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.servicesRequest.domains,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.servicesRequest,
                                        "domains",
                                        $$v
                                      )
                                    },
                                    expression: "servicesRequest.domains"
                                  }
                                },
                                _vm._l(_vm.otherDomainsAvailable, function(
                                  domain
                                ) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: domain } },
                                        [_vm._v(_vm._s("." + domain.extension))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("el-collapse-transition", [
                          _vm.domainInversion
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "domains-availability-section_price py-3 mt-3"
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "domains-availability-section_price_title mb-0"
                                    },
                                    [_vm._v("Inversión Anual")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "domains-availability-section_price_number"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPrice(_vm.domainInversion)
                                        ) + " pesos (más IVA)"
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _vm.domainRequested.isAvailable
                              ? _c(
                                  "el-checkbox-group",
                                  {
                                    staticClass: "d-inline-block",
                                    model: {
                                      value: _vm.servicesRequest.domains,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.servicesRequest,
                                          "domains",
                                          $$v
                                        )
                                      },
                                      expression: "servicesRequest.domains"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass:
                                          "domains-availability-section_cb",
                                        attrs: { label: _vm.domainRequested }
                                      },
                                      [
                                        _vm._v(
                                          "Solicitar el registro\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "domains-availability-section_arrow-btn ml-lg-3",
                                on: {
                                  click: function($event) {
                                    return _vm.goToHosting()
                                  }
                                }
                              },
                              [_c("z-icon", { attrs: { icon: "arrow" } })],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row layout-horizontal_middle-page domains-section justify-content-center align-items-center full-height-lg py-3 py-lg-0"
        },
        [
          _c(
            "div",
            { staticClass: "col px-2 px-lg-4" },
            [
              _c("h2", { staticClass: "domains-section_title" }, [
                _vm._v("DOMINIOS "),
                _c("span", [_c("z-icon", { attrs: { icon: "world" } })], 1)
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "domains-section_table" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.domainExtensions, function(extension) {
                    return _c("tr", { key: extension.id }, [
                      _c("td", [_vm._v("." + _vm._s(extension.extension))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatPrice(extension.pricing)))
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("el-collapse-transition", [
                _vm.servicesRequest.domains.length
                  ? _c(
                      "div",
                      { staticClass: "domains-section_requested mt-2 mt-lg-5" },
                      [
                        _c(
                          "h3",
                          { staticClass: "domains-section_requested_title" },
                          [_vm._v("Dominio(s) Solicitado")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "domains-section_requested_domains" },
                          _vm._l(_vm.servicesRequest.domains, function(domain) {
                            return _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-auto domains-section_requested_domains_remove"
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    on: {
                                      click: function($event) {
                                        return _vm.removeDomainFromRequired(
                                          domain
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(_vm._s(domain.domain))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.getDomainExtensionPrice(
                                        domain.extension
                                      )
                                    )
                                  )
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row hosting-section align-items-center full-height-lg py-3 py-lg-0"
        },
        [
          _c("div", { staticClass: "col px-2 px-lg-4" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "hosting-section_title" }, [
                  _vm._v("Hospedaje "),
                  _c("span", [_c("z-icon", { attrs: { icon: "hosting" } })], 1)
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Contrata un plan de hospedaje")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start"
                },
                [
                  _c("div", { staticClass: "hosting-plan" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "hosting-plan_footer py-2" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "custom-radio",
                            attrs: { label: "MICROZIP" },
                            model: {
                              value: _vm.servicesRequest.hosting,
                              callback: function($$v) {
                                _vm.$set(_vm.servicesRequest, "hosting", $$v)
                              },
                              expression: "servicesRequest.hosting"
                            }
                          },
                          [_vm._v("$990.00\n\t\t\t\t\t\t\t")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(5)
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start"
                },
                [
                  _c("div", { staticClass: "hosting-plan" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "hosting-plan_footer py-2" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "custom-radio",
                            attrs: { label: "PYMEZIP" },
                            model: {
                              value: _vm.servicesRequest.hosting,
                              callback: function($$v) {
                                _vm.$set(_vm.servicesRequest, "hosting", $$v)
                              },
                              expression: "servicesRequest.hosting"
                            }
                          },
                          [_vm._v("$2,990.00\n\t\t\t\t\t\t\t")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(7)
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start"
                },
                [
                  _c("div", { staticClass: "hosting-plan" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "hosting-plan_footer py-2" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "custom-radio",
                            attrs: { label: "PROZIP" },
                            model: {
                              value: _vm.servicesRequest.hosting,
                              callback: function($$v) {
                                _vm.$set(_vm.servicesRequest, "hosting", $$v)
                              },
                              expression: "servicesRequest.hosting"
                            }
                          },
                          [_vm._v("$4,990.00\n\t\t\t\t\t\t\t")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(9)
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-start"
                },
                [
                  _c("div", { staticClass: "hosting-plan" }, [
                    _vm._m(10),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "hosting-plan_footer py-2" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "custom-radio",
                            attrs: { label: "MASTERZIP" },
                            model: {
                              value: _vm.servicesRequest.hosting,
                              callback: function($$v) {
                                _vm.$set(_vm.servicesRequest, "hosting", $$v)
                              },
                              expression: "servicesRequest.hosting"
                            }
                          },
                          [_vm._v("$9,990.00\n\t\t\t\t\t\t\t")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(11)
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row mt-4 justify-content-center justify-content-lg-between"
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm" },
                  [
                    _c(
                      "el-radio",
                      {
                        staticClass: "custom-radio hosting-section_last-radio",
                        attrs: { label: "none" },
                        model: {
                          value: _vm.servicesRequest.hosting,
                          callback: function($$v) {
                            _vm.$set(_vm.servicesRequest, "hosting", $$v)
                          },
                          expression: "servicesRequest.hosting"
                        }
                      },
                      [_vm._v("NO deseo hospedaje\n\t\t\t\t\t")]
                    ),
                    _vm._v(" "),
                    _vm._m(12)
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-auto pt-3 pt-sm-0" },
                  [
                    _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
                      _vm.servicesRequest.domains.length ||
                      _vm.servicesRequest.hosting !== "none"
                        ? _c(
                            "button",
                            {
                              staticClass: "hosting-section_btn mr-lg-5",
                              on: { click: _vm.showRequestServiceDialog }
                            },
                            [_vm._v("SOLICITAR SERVICIOS\n\t\t\t\t\t\t")]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row justify-content-center justify-content-lg-start"
              },
              [
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "a",
                    {
                      staticClass: "hosting-section_terms_download d-flex mt-3",
                      attrs: {
                        href: _vm.$asset("docs/politicas.pdf"),
                        target: "_blank"
                      }
                    },
                    [
                      _vm._m(13),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pl-2" },
                        [
                          _c("z-icon", {
                            staticClass: "hosting-section_terms_download_icon",
                            attrs: { icon: "pdf-file" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("z-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "En nuestra agencia de diseño web en Guadalajara también contamos con el servicio de registro de dominios y planes de hospedaje."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "Ofrecemos este servicio para facilitarle a nuestros clientes el proceso de compra de dominios y elección de planes de hospedaje."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "Contamos con varios planes y paquetes para todo tipo de proyectos.\n\t\t\t"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Escribe el nombre que deseas registrar, elije su terminación y "),
      _c("br", { staticClass: "d-none d-lg-block" }),
      _vm._v("\n\t\t\t\tte mostraremos las opciones disponibles.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("TERMINACIÓN")]),
        _vm._v(" "),
        _c("td", [_vm._v("Inversión Anual")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "domains-section_terms mt-3" }, [
      _vm._v(
        "*Cualquier otra terminación favor de ponerse en contacto@zipvisual.com\n\t\t\t\t"
      ),
      _c("br"),
      _vm._v("\n\t\t\t\t*No se incluyen dominios Premium"),
      _c("br"),
      _vm._v("\n\t\t\t\t*Dominios sujetos a disponibilidad"),
      _c("br"),
      _vm._v("\n\t\t\t\t*Precios anuales más IVA"),
      _c("br"),
      _vm._v("\n\t\t\t\t*La consulta de disponibilidad puede no ser exacta"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_header py-2" }, [
      _c("h3", { staticClass: "hosting-plan_header_title" }, [
        _vm._v("MICRO"),
        _c("span", [_vm._v("ZIP")])
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "hosting-plan_image",
        attrs: { src: require("../../imgs/server.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "hosting-plan_size mt-3" }, [_vm._v("1 GB")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_included mt-2" }, [
      _c("p", [
        _vm._v("\n\t\t\t\t\t\t\t\t20 GB Transferencia mensual "),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tCuentas de correo"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tBD MySql"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tWebmail\n\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_header py-2" }, [
      _c("h3", { staticClass: "hosting-plan_header_title" }, [
        _vm._v("PYME"),
        _c("span", [_vm._v("ZIP")])
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "hosting-plan_image",
        attrs: { src: require("../../imgs/server.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "hosting-plan_size mt-3" }, [_vm._v("3 GB")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_included mt-2" }, [
      _c("p", [
        _vm._v("\n\t\t\t\t\t\t\t\t40 GB Transferencia mensual "),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tCuentas de correo"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tBD MySql"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tWebmail\n\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_header py-2" }, [
      _c("h3", { staticClass: "hosting-plan_header_title" }, [
        _vm._v("PRO"),
        _c("span", [_vm._v("ZIP")])
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "hosting-plan_image",
        attrs: { src: require("../../imgs/server.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "hosting-plan_size mt-3" }, [_vm._v("5 GB")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_included mt-2" }, [
      _c("p", [
        _vm._v("\n\t\t\t\t\t\t\t\t70 GB Transferencia mensual "),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tCuentas de correo"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tBD MySql"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tWebmail\n\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_header py-2" }, [
      _c("h3", { staticClass: "hosting-plan_header_title" }, [
        _vm._v("MASTER"),
        _c("span", [_vm._v("ZIP")])
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "hosting-plan_image",
        attrs: { src: require("../../imgs/server.png"), alt: "" }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "hosting-plan_size mt-3" }, [_vm._v("10 GB")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hosting-plan_included mt-2" }, [
      _c("p", [
        _vm._v("\n\t\t\t\t\t\t\t\t70 GB Transferencia mensual "),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tCuentas de correo"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tBD MySql"),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\t\t\tWebmail\n\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "hosting-section_terms" }, [
      _vm._v(
        "\n\t\t\t\t\t\t*Si requiere un servicio especial favor de ponerse en "
      ),
      _c("a", { attrs: { href: "mailto:contacto@zipvisual.com" } }, [
        _vm._v("contacto@zipvisual.com")
      ]),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\t*Precios anuales más IVA\n\t\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n\t\t\t\t\t\t\tDESCARGAR POLITICAS EN "),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\t\tSOPORTE DE DOMINIOS Y "),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\t\tHOSPEDAJES\n\t\t\t\t\t\t")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }