<template>
	<el-dialog
			title="Aviso de privacidad"
			:visible="isPrivacyAdviceVisible"
			:before-close="updateCloseState"
	>

		<p>
			<b>CAPITULO I. RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES.<br>
				RICARDO LARA MILLÁN</b>, persona física, con capacidad legal, propietario de la negociación conocida como
			<b>ZIPVISUAL</b>,
			ubicada en el número 610 de la calle Islandia, Colonia Revolución, Código Postal 44400, en Guadalajara, Jalisco; es
			responsable del uso y protección de datos personales en cumplimiento con los artículos 15, 16, 17 y demás relativos
			a la Ley Federal de Protección de Datos Personales en Posesión de Particulares y de la legislación aplicable. Por
			lo que hace de su conocimiento la finalidad del tratamiento de sus datos personales, los medios que limiten su
			divulgación, los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los mismos, así como en su
			caso, la transferencia éstos, la revocación de su consentimiento para el tratamiento y el procedimiento aplicable
			según corresponda.
		</p>
		<p>
			<b>CAPITULO II. ENCARGADO Y LÍDER DE PRIVACIDAD.</b><br>
			Para información y todo lo relacionado con el tratamiento y protección de sus datos personales usted podrá
			contactarse con el encargado de privacidad, <b>C. RICARDO LARA MILLÁN</b>, a través del correo electrónico:
			contacto@zipvisual.com, siguiendo con los procedimientos que se establecen en el presente aviso de privacidad.
			Señalando a su vez, como domicilio para oír y recibir todo tipo de notificaciones la finca marcada con el número
			610 de la calle Islandia, Colonia Revolución, Código Postal 44400, en Guadalajara, Jalisco.
		</p>
		<p>
			<b>CAPITULO III. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES</b><br>
			Los datos personales que recabe de usted, los utilizaré para las siguientes finalidades:
		</p>
		<ol>
			<li>Envío de información en relación a productos y servicios;</li>
			<li>Cotizaciones;</li>
			<li>Proveer, gestionar, solicitar e informar acerca de los servicios contratados;</li>
			<li>Llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas
				de cualquier relación jurídica y comercial que establezcamos con motivo de la compra de nuestros productos y
				servicios.
			</li>
			<li>Llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas
				de cualquier relación jurídica y comercial que establezcamos con motivo de suministro hacia mí, venta de productos
				de interés de la empresa, así como ofrecimiento y prestación de productos y servicios con los mismos fines.
			</li>
			<li>Registrar sus datos a nuestro sistema de información.</li>
			<li>Almacenar sus datos personales en nuestros directorios, bases de datos correspondientes y expedientes con fines
				administrativos, contables, legales y fiscales.
			</li>
			<li>Utilizar sus datos personales en contratos comerciales, así como en los diversos instrumentos jurídicos que
				amparen la relación comercial que hayamos contraído.
			</li>
			<li>Facturación y expedición de documentos con sus datos personales en cumplimiento de las obligaciones
				comerciales.
			</li>
			<li>Dar seguimiento al proceso de cobranza y atención al cliente.</li>
			<li>Otorgamiento o solicitud de garantías derivadas de nuestra relación comercial.</li>
			<li>Devolución de mercancías.</li>
			<li>En su caso, para los procesos administrativos, judiciales y/o jurisdiccionales que involucren al titular de los
				datos personales, derivado del incumplimiento de obligaciones de contraídas en la relación comercial.
			</li>
			<li>Para identificarlo y permitirle el acceso a nuestras instalaciones.</li>
			<li>Video-vigilancia dentro de nuestras instalaciones, como por ejemplo el uso de circuitos cerrados de televisión,
				así como cámaras de video, por lo que usted, por el hecho de ingresar a nuestras instalaciones, está aceptando
				tácitamente su consentimiento para que su imagen y voz sean monitoreados e incluso grabados, para los fines ya
				mencionados.
			</li>
		</ol>
		<p>NOTA: Es importante que usted tenga en cuenta que en caso de no contar con sus datos personales no estaría en
			posibilidad de llevar a cabo los fines para los cuales se requieren y en tal caso no tendría ningún tipo de
			responsabilidad para la debida consecución de los mismos.</p>
		<p>De manera adicional, utilizaré su información personal para las siguientes finalidades secundarias, las cuales
			<b>no
				son necesarias</b> para el servicio solicitado, pero que me permiten y facilitan brindarle una mejor atención:
		</p>
		<ol>
			<li>Comunicaciones con fines de mercadotecnia, darle a conocer campañas publicitarias, así como promociones,
				productos y servicios nuevos y nuestra participación en redes sociales.
			</li>
			<li>Realización de encuestas, estadísticas y estudios de mercado.</li>
			<li>Invitación a eventos.</li>
			<li>Y en general para las actividades encaminadas a promover, mantener, mejorar y evaluar nuestros productos y
				servicios.
			</li>
		</ol>
		<p>Si usted no está de acuerdo para que sus datos personales sean tratados para las finalidades secundarias
			señaladas, puede negarme su consentimiento desde este momento, enviando un correo electrónico a
			contacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o bien, presentándola de
			manera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400, en Guadalajara,
			Jalisco; con atención al líder de privacidad, en el que especifique la o las finalidades secundarias para las que
			no desea que utilicemos sus datos personales. Su negativa en este sentido, no afectará el ejercicio de los derechos
			y cumplimiento de las obligaciones que demande la relación comercial que hayamos contraído, lo anterior de
			conformidad a lo establecido en las fracciones ll y lll del artículo 16 de la Ley Federal de Protección de Datos
			Personales en Posesión de Particulares.</p>
		<p><b>CAPITULO IV. FORMA DE OBTENCIÓN DE LOS DATOS PERSONALES</b><br>
			Puedo obtener información a través de los siguientes medios:
		</p>
		<ul>
			<li>Cuando me la proporciona directamente por escrito;</li>
			<li>En forma verbal;</li>
			<li>Correo electrónico o formularios de Internet.</li>
			<li>Medios telefónicos;</li>
			<li>Cualquier otra forma permitida por la ley.</li>
		</ul>
		<p><b>CAPITULO V. DATOS PERSONALES QUE SE RECABAN</b><br>
			Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaré los siguientes datos
			personales:
		</p>
		<ul>
			<li>Nombre Completo y/o razón social, Nacionalidad, Clave Única de Registro de Población (CURP), Registro
				Federal de Contribuyentes (RFC), Lugar y fecha de Nacimiento, Sexo, Estado Civil, Edad, Estatura, Peso, Domicilio
				particular, domicilio comercial, número de teléfono comercial, Número de teléfono particular; Número de celular,
				pagina Web, Correo electrónico, Firma autógrafa, Fotografía, Huella dactilar.
			</li>
		</ul>
		<p>
			Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de
			privacidad, requiero los siguientes, los cuales se consideran sensibles, incluyendo los datos personales
			financieros y patrimoniales, mismos que requieren protección especial, y en los cuales para su tratamiento
			solicitaré me otorgue su consentimiento previamente de manera expresa y por escrito, de conformidad al artículo 9 y
			16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares:
		</p>
		<ul>
			<li>Financieros: Historial crediticio en lo general, estados financieros, libros contables, títulos accionarios.
			</li>
			<li>Patrimoniales: Bienes Patrimoniales en general que posee.</li>
		</ul>
		<p>
			<b>CAPITULO VI. MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE SUS DATOS PERSONALES Y MEDIOS PARA LIMITAR EL USO Y/O
				DIVULGACIÓN DE LOS MISMOS.</b><br>
			Le garantizo que, su información personal, será resguardada bajo estricta confidencialidad, y para prevenir
			razonablemente el uso o divulgación indebida de la misma, he implementado medidas de seguridad físicas, técnicas,
			administrativas y legales de conformidad con la normatividad aplicable. En particular, cuento , políticas de
			privacidad, así como cursos de capacitación a mis empleados encargados de los mismos, tenemos el acceso restringido
			a información personal (sólo trabajadores autorizados), cuento con un concentrado de datos personales en bases de
			datos en las que trabajamos bajo seguridad informática (debidamente clasificados por categoría de datos); así como
			personal responsable del análisis de tratamiento y riesgo de los mismos, y cláusulas contractuales que atienden a
			este punto, como las penalidades en caso de violación de éstas, en todos nuestros contratos laborales en toda la
			estructura legal de la empresa, lo anterior en cumplimiento del artículo 14 de la Ley Federal de Protección de
			Datos Personales en Posesión de Particulares. <br>
			Cabe mencionar que dentro del presente aviso de privacidad le establezco en cada capítulo correspondiente, los
			medios en los que podrá ejercer sus derechos conforme a la ley y el procedimiento para concretar los mismos; en
			caso de requerir información adicional podrá solicitarla directamente con el líder de privacidad en los medios ya
			establecidos.
		</p>
		<p>
			<b>CAPITULO VII. DERECHOS ARCO Y DE REVOCACIÓN DEL CONSENTIMIENTO DEL TITULAR DE LOS DATOS PERSONALES.</b><br>
			En los términos permitidos por la legislación vigente aplicable, el titular de los datos personales tiene el
			derecho de solicitar el acceso, rectificación, cancelación y oposición (ARCO) de los mismos:
		</p>
		<ul>
			<li>El titular de los datos personales tiene derecho de conocer qué datos personales tenemos de usted, para qué los
				utilizamos y las condiciones de uso que les damos (ACCESO).
			</li>
			<li>Solicitar la corrección de su información personal en caso que esté desactualizada, sea inexacta, incompleta o
				errónea (RECTIFICACION).
			</li>
			<li>Solicitar la eliminación y no tratamiento de sus datos personales cuando considere que los mismos no están
				siendo utilizados conforme a las disposiciones previstas en la normatividad aplicable, salvo los casos previstos
				en el artículo 26 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (CANCELACION).
			</li>
			<li>El titular tendrá derecho en todo momento y por causa legítima a oponerse al tratamiento de sus datos
				personales (OPOSICION).
			</li>
		</ul>
		<p>Lo anterior con fundamento en los artículos 23, 24, 25 y 27 de la Ley Federal de Protección de Datos Personales
			en Posesión de Particulares. <br>
			Usted puede revocar el consentimiento que, en su caso, me haya otorgado para el tratamiento de sus datos
			personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podré atender su solicitud o
			concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiera seguir tratando sus
			datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento
			implicará que no le pueda seguir prestando el servicio que me solicitó o la conclusión de su relación conmigo.
		</p>
		<p>
			<b>CAPITULO VIII. DE LOS MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RATIFICACIÓN, CANCELACIÓN U OPOSICIÓN (ARCO)
				DE LOS DATOS PERSONALES.</b><br>
			El titular podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus
			datos, respecto al capítulo V, presentando una solicitud de conformidad con el siguiente procedimiento: <br><br>
			Realizar su solicitud enviando un correo electrónico con el asunto “EJERCICIO DE LOS DERECHOS DE ARCO” a la
			siguiente dirección: contacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o
			bien, presentándola de manera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal
			44400, en Guadalajara, Jalisco; dirigido al C. RICARDO LARA MILLÁN, en su calidad de encargado de privacidad para
			atender sus solicitudes.
		</p>
		<ul>
			<li>El nombre del titular de los datos personales, su domicilio y un medio alterno para comunicarle la respuesta a
				su solicitud;
			</li>
			<li>Los documentos que acrediten la identidad del titular de los datos personales o, en su caso, si lo hace un
				tercero acreditar la representación legal;
			</li>
			<li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los
				derechos de ARCO; y
			</li>
			<li>Cualquier otro elemento o documento que facilite la localización de sus Datos Personales; y</li>
			<li>En su caso, las solicitudes de rectificación de datos personales, el titular deberá indicar las modificaciones
				a realizarse y aportar la documentación que sustente su petición.
			</li>
		</ul>
		<p>En caso de que se aprecie que la documentación tenga tachaduras, este alterada o se tenga duda razonable de ser
			apócrifa, que no sea el titular, o no ser el legítimo representante, el que suscribe RICARDO LARA MILLÁN, me
			reservo el derecho a solicitar cualquier otro documento o acción para comprobar y acreditar la titularidad de los
			datos, por lo que se le podrá solicitar que presente la documentación en original o copias certificadas en la
			oficina del responsable. <br>
			Si faltara alguna información en su solicitud se le solicitará subsane la omisión a la brevedad.<br>
			Si cumpliera con todas las formalidades requeridas para dicho trámite se dará respuesta en un plazo máximo de
			veinte días, contados a partir de la fecha en que se recibió su petición o a partir de la fecha en que haya
			subsanado en su totalidad las prevenciones. El plazo antes referido podrá ser ampliado una sola vez por un periodo
			igual, siempre y cuando así lo justifiquen las circunstancias del caso. De conformidad con el artículo 32 de la ley
			de la materia.
		</p>
		<p><b>CAPITULO IX. MEDIOS PARA EJERCER SU DERECHO DE REVOCACIÓN DEL CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS
			PERSONALES</b><br>
			Para revocar el consentimiento que me ha otorgado deberá enviar un correo electrónico a contacto@zipvisual.com o
			mediante Carta por escrito enviada vía Correo Postal, mensajería o bien, presentándola de manera personal al
			domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400, en el municipio de Guadalajara,
			Jalisco; con el asunto
			<b>“REVOCACIÓN DE CONSENTIMIENTO”</b> y cumpliendo con los requisitos establecidos en el
			procedimiento del capítulo VI se ARCO señalado en el capítulo VIIl de este aviso de privacidad.
		</p>
		<p><b>CAPITULO X. TRANSFERENCIA DE DATOS PERSONALES</b><br>
			Hago de su conocimiento que
			<b>NO REALIZO TRANSFERENCIAS</b> de información a terceros sin su previa autorización.<br><br>
			Usted puede manifestar su negativa para la transferencia de la que requiero su consentimiento, enviando un correo
			electrónico a contacto@zipvisual.com o mediante Carta por escrito enviada vía Correo Postal, mensajería o bien,
			presentándola de manera personal al domicilio: Calle Islandia #610 de la Colonia Revolución, Código Postal 44400,
			en Guadalajara, Jalisco, si no lo hace, entenderé que me ha otorgado su consentimiento.<br>
			Solo se podrán hacer transferencias sin su consentimiento de acuerdo a lo previsto en el artículo 37 de la Ley
			Federal de Protección de datos Personales en Posesión de los Particulares, así como a realizar esta transferencia
			en los términos que fija esa ley, a lo que establecemos lo supuesto:
		</p>
		<ul>
			<li>Cuando la transferencia de datos personales esté prevista en una Ley o Tratado en los que México sea parte;</li>
			<li>Cuando la transferencia de datos personales esté prevista en una Ley;</li>
			<li>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control
				común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo del responsable que opere
				bajo los mismos procesos y políticas internas;
			</li>
			<li>Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del
				titular, por el responsable y un tercero;
			</li>
			<li>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
				procuración o administración de justicia;
			</li>
			<li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso
				judicial; y
			</li>
			<li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el
				responsable y el titular.
			</li>
		</ul>
		<p>
			<b>CAPITULO XI. CAMBIOS AL AVISO DE PRIVACIDAD Y PROCEDIMIENTO PARA DARLO A CONOCER A LOS TITULARES DE LOS DATOS
				PERSONALES.</b><br>
			El que suscribe
			<b>RICARDO LARA MILLÁN</b>, podré modificar y/o actualizar el presente aviso de privacidad, y le
			informaré
			al respecto de cualquier cambio de los referidos a través del portal Web: www.zipvisual.com, mismo que se pone a su
			entera disposición para dicho fin, el cual se encuentra disponible las 24 horas del día y los 365 días del año.
		</p>
		<p><b>CAPITULO XII. UTILIZACIÓN DE COOKIES Y WEB BEACONS</b><br>
			Se hace de su conocimiento que las cookies son archivos de texto que son descargados automáticamente y almacenados
			en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten
			recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la
			visualización de las páginas en ese servidor, nombre y contraseña.<br><br>
			Le informamos que utilizamos cookies propias y de tercero técnico, personalizado y de análisis para determinar sus
			preferencias y mejorar nuestro servicio. Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer
			cómo hacerlo, consulte:<br>
			<a href="http://bit.ly/1jqKOFl">http://bit.ly/1jqKOFl</a>
			<a href="http://bit.ly/1lpLCNQ">http://bit.ly/1lpLCNQ</a>
			<a href="http://mzl.la/1JGJ5aS">http://mzl.la/1JGJ5aS</a>
			<a href="http://apple.co/1zYNGVH">http://apple.co/1zYNGVH</a>
		</p>
		<p><b>CAPITULO XIII. CONSENTIMIENTO</b><br>
			Le informo que previamente a que recabe sus datos personales, los considerados sensibles, así como los financieros
			y patrimoniales conforme el presente aviso de privacidad, le solicitaré me otorgue su consentimiento expreso y por
			escrito de conformidad a la Ley Federal de Protección de Datos Personales en Posesión de Particulares.
		</p>

		<div slot="footer" class="dialog-footer">
			<el-button @click="updateCloseState">Aceptar</el-button>
		</div>
	</el-dialog>
</template>

<script>
    export default {
        name: "ZPrivacyAdvice",
        computed: {
            isPrivacyAdviceVisible() {
                return this.$store.state.app.isPrivacyAdviceVisible;
            }
        },

        methods: {
            updateCloseState() {
                this.$store.commit("app/CLOSE_PRIVACY_ADVICE");
            }
        }
    }
</script>

<style scoped>

</style>