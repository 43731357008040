export const state = () => ({
    clients: STATE.clients,
});

export const actions = {
    async loadClients({commit}) {
        let clients = (await this.$axios.$get(`${ASSET_PATH}api/clients`)).data;
        commit('SET_CLIENTS', {});
    },
};

export const mutations = {
    SET_CLIENTS(state, payload) {
        state.clients = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}