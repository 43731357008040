<template>
	<div class="z-cloudcomputing">
		<img class="z-cloudcomputing_cloud" src="../../imgs/cloud.png" alt="">
		<img class="z-cloudcomputing_screen" src="../../imgs/screen.png" alt="">
	</div>
</template>

<script>
    export default {
        name: "ZCloudcomputing"
    }
</script>

<style scoped>

</style>