<template>
	<div class="layout-horizontal_wrapper container-fluid development-page">
		<z-header/>

		<div class="row layout-horizontal_page title-section">
			<div class="col-12 col-lg-6 order-lg-2 d-flex justify-content-center align-items-center full-height-lg py-1 py-lg-0 computer-section">
				<img class="computer-section_mouse" src="@/app/imgs/mouse.png" alt="">
			</div>
			<div class="col-12 col-lg-6 order-lg-1 d-flex justify-content-center align-items-center full-height-lg py-4 py-lg-0">
				<div class="pl-0 pl-lg-5">
					<h1 class="title-section_title"><span
							class="title-section_title_stroked">{{$t('PAGINAS')}}</span> {{$t('WEB')}}</h1>
					<h2 class="title-section_subtitle">{{$t('Sistemas & Apps')}}</h2>
					<p>Ofrecemos el servicio de diseño y desarrollo de paginas web, sistemas y administradores web a la medida, también diseñamos y desarrollamos Apps para Android e iOS o incluso CMS como Wordpress como parte de nuestras soluciones digitales.<br><br>Tenemos una solución a la medida de cada cliente, con un servicio integral que nos permite cubrir las necesidades con un diseño y desarrollo único, funcional y adecuado para cada proyecto.
						<br><br>Contamos con asesoría para tu proyecto.
					</p>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page apps-section align-items-center full-height-lg py-4 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 px-lg-4">
						<div class="info-service info-service--white">
							<span class="info-service_number">01</span>
							<h2 class="info-service_title">Paginas Web y Apps</h2>
							<div class="info-service_description">
								<p>Diseñamos y desarrollamos sitios web, únicos, funcionales y responsivos para todos los dispositivos móviles y a la medida de cada cliente logrando una mejor experiencia de usuario.
									<br><br> Creamos tu App en Android o iOS de acuerdo a las necesidades de cada proyecto.
								</p>
								<ul>
									<li>Diseño Responsivo de Paginas Web</li>
									<li>Rediseño de Sitios Web</li>
									<li>Diseño y desarrollo de Apss para Android + iOS</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 d-flex flex-row justify-content-center align-items-end justify-content-lg-end">
						<div class="apps-section_icons mb-5 mr-3">
							<z-icon icon="apple"></z-icon>
							<z-icon icon="android"></z-icon>
						</div>
						<z-phone class="mt-lg-n4 d-none d-lg-block"></z-phone>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 order-lg-2 px-lg-4">
						<div class="info-service">
							<span class="info-service_number">02</span>
							<h2 class="info-service_title">Desarrollo de Sistemas</h2>
							<div class="info-service_description">
								<p>Desarrollamos cualquier sistema de acuerdo a las necesidades de cada proyecto según los requerimientos de nuestros clientes obteniendo el mayor beneficio en cuanto a funcionalidad y estética.</p>
								<ul>
									<li>Sistemas a la medida</li>
									<li>Administradores de contenido web</li>
									<li>Sistemas Administrativos o empresariales</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 justify-content-center justify-content-lg-end order-lg-1 d-none d-lg-flex">
						<z-fingerprint class="my-n4"></z-fingerprint>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 px-lg-4">
						<div class="info-service">
							<span class="info-service_number">03</span>
							<h2 class="info-service_title">E-Commerce</h2>
							<div class="info-service_description">
								<p>Internet es el presente y futuro de las ventas, Zipvisual te ofrece el diseño y desarrollo de tu propia tienda en línea ( e-commerce ) para vender tus productos con un administrador web que te permite poner promociones, actualizar tus productos y dar seguimiento a los pedidos de una forma sencilla.</p>
								<ul>
									<li>Catálogo de Productos</li>
									<li>Tienda Online</li>
									<li>Metódos de Pago</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-12 py-3 justify-content-center d-none d-lg-flex">
						<z-shoppingcar/>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_middle-page sistems-section align-items-center full-height-lg py-4 py-lg-0">
			<div class="col">
				<div class="row">
					<div class="col-12 order-1 order-lg-2 px-lg-4">
						<div class="info-service">
							<span class="info-service_number">04</span>
							<h2 class="info-service_title">Dominios y Hospedaje</h2>
							<div class="info-service_description">
								<p>Te ofrecemos el servicio de registro de dominios y los mejores planes de hospedaje para tu página web. Además te brindamos el servicio de soporte técnico para tu página web.</p>
								<ul class="mt-3">
									<li>Registro de Dominios</li>
									<li>Hospedaje Web</li>
								</ul>
								<router-link :to="{name:'hosting'}">
									<button class="info-service_btn mt-3">
										Más Información
									</button>
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-lg-12 py-3 justify-content-center order-2 order-lg-1 d-none d-lg-flex">
						<z-cloudcomputing></z-cloudcomputing>
					</div>
				</div>
			</div>
		</div>
		<div class="row layout-horizontal_600-page brochure-section">
			<div class="col-12 d-flex flex-column justify-content-around align-items-center full-height-lg py-3 py-lg-0">
				<img src="@/app/imgs/icon-responsive-design.png" alt="">
				<a :href="$asset('docs/brochure-zipvisual.pdf')" target="_blank">
					<button class="circle-button circle-button--big brochure-button mt-3">
						<span>brochure</span>
						<z-icon icon="download"/>
					</button>
				</a>
			</div>
		</div>
		<z-footer/>
	</div>
</template>

<script>
    import ZFooter from "@/app/js/components/ZFooter";
    import ZHeader from "@/app/js/components/ZHeader";
    import ZIcon from "@/app/js/components/ZIcon";
    import ZPhone from "@/app/js/components/ZPhone";
    import ZFingerprint from "@/app/js/components/ZFingerprint";
    import ZCloudcomputing from "@/app/js/components/ZCloudcomputing";
    import ZShoppingcar from "@/app/js/components/ZShoppingcar";
    import KScalableContent from "@/app/js/components/KScalableContent";

    export default {
        components: {KScalableContent, ZShoppingcar, ZCloudcomputing, ZFingerprint, ZPhone, ZIcon, ZFooter, ZHeader},
        metaInfo() {
            return {
				title: this.$t("Diseñamos páginas web, tiendas online, creamos aplicaciones móviles y sistemas web "),
				meta: [{
					vmid: 'description',
					name: 'description',
					content: this.$t("Desarrollamos soluciones para tue empresa desde páginas web, tiendas online y aplicaciones móviles para solucionar las necesidades de tu empresa. ¡Llámanos 3327913995!")
				}],
            }
        },
    }
</script>

<style scoped>

</style>
