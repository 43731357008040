<template>
	<div v-loading="loading">
		<el-form ref="newsletterForm" :model="form">
			<el-form-item prop="name" :rules="rules.required">
				<el-input v-model="form.name" placeholder="Nombre completo*"></el-input>
			</el-form-item>
			<el-form-item prop="email" :rules="[...rules.required, ...rules.email]">
				<el-input v-model="form.email" placeholder="Email*"></el-input>
			</el-form-item>
		</el-form>
		<button class="circle-button circle-button--small" @click="sendForm">
			<z-icon icon="chevron-right" :center="false"/>
		</button>
	</div>
</template>

<script>
    import ZIcon from "./ZIcon";

    export default {
        name: "ZNewsletter",
        components: {ZIcon},
        computed: {
            rules() {
                return {
                    email: [{type: "email", trigger: 'blur', message: 'Ingrese un email válido.'}],
                    required: [{required: true, trigger: 'blur', message: 'Este campo es requerido.'}]
                }
            }
        },
        data() {
            return {
                loading: false,
                form: {}
            }
        },
        methods: {
            sendForm() {
                this.$refs['newsletterForm'].validate((isValid) => {
                    if (isValid) {
                        this.loading = true;
                        $.post(LINKS.registryNewsletter, this.form)
                            .done(() => {
                                this.$notify({
                                    title: 'Éxito',
                                    message: 'El registro se realizo correctamente, ¡Muchas gracias!.',
                                    type: 'success'
                                });

                                this.form = {};
                            })
                            .fail((e) => {
                                console.error(e);
                                this.$notify({
                                    title: 'Error',
                                    message: 'Se ha producido un error al enviar el formulario, porfavor intente más tarde.',
                                    type: 'error'
                                })
                            })
                            .always(() => {
                                this.loading = false;
                            });
                    }
                })
            }
        }
    }
</script>
