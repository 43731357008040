<template>
	<div class="z-fingerprint">
		<img src="../../imgs/fingerprint.png" alt="">
		<img class="z-fingerprint_c1" src="../../imgs/fp-circle-1.png" alt="">
		<img class="z-fingerprint_c2" src="../../imgs/fp-circle-2.png" alt="">
	</div>
</template>

<script>
    export default {
        name: "ZFingerprint"
    }
</script>

<style scoped>

</style>